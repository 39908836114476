import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faCircleXmark,
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./FAQItem.module.css";

const FAQItem = ({
  itemName,
  itemDescription,
  value,
  valueDescription,
  selected,
  color,
}) => {
  const [showingValueDescription, setShowingDescription] = useState(false);

  return (
    <div className={styles.container}>
      {showingValueDescription ? (
        <>
          <div className={styles.descriptionContainer}>
            <div className={styles.itemContainer}>
              <div className={styles.itemInfoContainer}>
                <p className={`${color} ${styles.itemName}`}>{itemName}</p>
                <FontAwesomeIcon
                  className={styles.icon}
                  icon={faChevronDown}
                  onClick={() => setShowingDescription(false)}
                />
              </div>
              <p className={styles.itemDescription}>{itemDescription}</p>
            </div>
            {value && (
              <p className={styles.valueDescription}>
                <>
                  {valueDescription ? (
                    <>
                      <span className={styles.valueSpan}>{value}</span> -{" "}
                      {valueDescription}
                    </>
                  ) : (
                    <>
                      <span className={styles.valueSpan}>{value}</span>
                    </>
                  )}
                </>
              </p>
            )}
          </div>
        </>
      ) : (
        <div
          className={styles.itemRow}
          onClick={() => setShowingDescription(true)}
        >
          <p className={styles.item}>
            <span
              className={`${color}
            ${styles.itemName}
            `}
            >
              {itemName}
            </span>
            {value}
          </p>
          <span className={styles.infoIcon}>
            {showingValueDescription ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </span>
        </div>
      )}
    </div>
  );
};

export default FAQItem;
