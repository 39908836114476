import React from "react";
import FAQItem from "./FAQItem/FAQItem";
import styles from "./FAQ.module.css";

const FAQ = () => {
  const faqs = [
    {
      question:
        "How long will it take me to develop a command of my target language using Iter Lexici?",
      answer:
        "It will vary depending on your native language and your target language. However, given our strict adherence to theory, we are confident that the time will be significantly reduced when compared to other solutions. We will be testing these claims as the app gains users and therefore more data.",
    },
    {
      question: "What languages are supported by Iter Lexici?",
      answer:
        "The initial target language is Spanish. We plan to support as many languages as possible given literary and technological constraints. All target languages offered will be available as base languages and vice versa.",
    },
    {
      question: "Why should I learn via input?",
      answer:
        "Input-based methods focus on promoting a feel for a language via comprehension. Speech, according to the theory, emerges as enough comprehension is reached.",
    },
    {
      question: "Can beginners use Iter Lexici?",
      answer:
        "Beginners can and absolutely should use Iter Lexici. While the texts offered are certainly high-school level and beyond, you have an ample amount of technology to aid you. Were you reading books above your level without assistance, it could be considered counterproductive. That is, however, not the case here. You may start, so to speak, with the final boss and use technology to win.",
    },
    {
      question: "How does the app handle archaic language?",
      answer:
        "Archaic and obsolete words are identified and have their modern equivalents provided inline with the text. We don't want to cast aside beautiful works of literature simply because they occasionally use words outside of the modern lexicon.",
    },
  ];

  const content = (
    <div className={styles.gridInfo_content}>
      <div className={styles.translationCol}>
        <h2 className={styles.header}>FAQ</h2>
        <div className={styles.translationContent}>
          {faqs.map((faq, index) => (
            <FAQItem
              key={index}
              itemName={faq.question}
              itemDescription={faq.answer}
            />
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <main className="page-wrapper">
      <div className="grid-center">{content}</div>
    </main>
  );
};

export default FAQ;
