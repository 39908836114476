import React, { useRef, useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import GoogleAuth from "../GoogleAuth";
import LoadMsg from "../../../components/shared/LoadMsg/LoadMsg";
import FormErrMsg from "../../../components/shared/FormErrMsg/FormErrMsg";
import useTitle from "../../../hooks/useTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightToBracket,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./Login.module.css";

const Login = () => {
  useTitle("Login");

  const { login, isLoading } = useContext(AuthContext);

  const navigate = useNavigate();

  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");
  // todo: change to username or email
  const [credential, setCredential] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setErrMsg("");
  }, [credential, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(credential, password);
      setCredential("");
      setPassword("");
      navigate("/library");
    } catch (err) {
      if (!err.status) {
        setErrMsg("no server response");
      } else {
        setErrMsg(err.data?.messageVerbose || err.data?.message);
      }
      errRef.current?.focus();
    }
  };

  let content;

  if (isLoading) {
    content = <LoadMsg msg="loading" size="2x" />;
  } else {
    content = (
      <>
        <h2 className={styles.heading}>
          {/* Login */}
          Login to
          <br></br>
          <span className={styles.il}> Iter Lexici</span>
        </h2>

        <GoogleAuth label="Continue with Google" />

        <div className="divider">or</div>

        {errMsg && (
          <FormErrMsg
            setErrMsg={setErrMsg}
            elements={[
              <p ref={errRef} aria-live="assertive" role="alert">
                {errMsg}
              </p>,
            ]}
          />
        )}

        <form className="form" onSubmit={handleSubmit}>
          {/* credential */}
          <div className="form__col">
            <div className="form__row">
              <label className="form__label" htmlFor="credential">
                Username or Email:
              </label>
            </div>
            <div className="form__row">
              <input
                className="form__input"
                type="text"
                id="credential"
                value={credential}
                onChange={(e) => setCredential(e.target.value)}
                autoComplete="off"
                required
                autoFocus
                tabIndex="1"
              />
            </div>
          </div>

          {/* password */}
          <div className="form__col">
            <div className={`form__row ${styles.passwordRow}`}>
              <label className="form__label" htmlFor="password">
                Password:
              </label>
              <Link
                to="/forgot-password"
                className={`${styles.link} ${styles.forgotPassword}`}
                tabIndex="5"
              >
                Forgot?
              </Link>
            </div>
            <div className="form__row relative">
              <input
                className="form__input"
                type={showPassword ? "text" : "password"}
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
                tabIndex="2"
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="form__showPassword"
                title={showPassword ? "Hide password" : "Show password"}
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
          </div>

          <button
            className={
              credential && password && !errMsg
                ? `form__submitButton`
                : `form__submitButton--disabled`
            }
            tabIndex="3"
          >
            <div className={styles.loginRow}>
              Login
              <FontAwesomeIcon icon={faRightToBracket} />
            </div>
          </button>
        </form>

        <p className={styles.signUp}>
          New to Iter Lexici?{" "}
          <Link to="/sign-up" className={styles.link} tabIndex="6">
            Sign up
          </Link>
        </p>
      </>
    );
  }

  return (
    <main className="page-wrapper">
      <div className="grid-center">
        <section className={styles.section}>{content}</section>
      </div>
    </main>
  );
};

export default Login;
