import React from "react";
import CreateNewLanguage from "./CreateNewLanguage";
import CreateNewCourse from "./CreateNewCourse";
import CreateNewBook from "./CreateNewBook";
import AutoCreateNewSentence from "./AutoCreateNewSentence";
import AutoCreateMultipleNewSentences from "./AutoCreateMultipleNewSentences/AutoCreateMultipleNewSentences";
import CheckSentencesHealth from "./CheckSentencesHealth";
import ResetUserVocabulary from "./ResetUserVocabulary";
// import UpdateFragment from "./UpdateFragment";
// import ReinitializeAllUsersVocabularies from "./ReinitializeAllUsersVocabularies";

import styles from "./AdminIndex.module.css";

const AdminIndex = () => {
  const content = (
    <>
      <CreateNewLanguage />
      <CreateNewCourse />
      <CreateNewBook />
      <AutoCreateNewSentence />
      <AutoCreateMultipleNewSentences />
      <CheckSentencesHealth />
      <ResetUserVocabulary />
      {/* <UpdateFragment /> */}
      {/* <ReinitializeAllUsersVocabularies /> */}
    </>
  );

  return <section className={styles.container}>{content}</section>;
};

export default AdminIndex;
