import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { setCredentials, clearCredentials } from "./authSlice";
import {
  useRefreshMutation,
  useSignUpMutation,
  useResendVerificationEmailMutation,
  useLoginMutation,
  useLogoutMutation,
  useSendForgotPasswordEmailMutation,
  useSubmitForgotPasswordCodeMutation,
} from "./authApiSlice";
import {
  useUpdatePasswordMutation,
  useUpdateStripeCheckoutSessionMutation,
} from "../profile/profileApiSlice";
import { stripeLookupKey } from "../../config/stripeLookupKey";
import {
  setNotification,
  clearNotification,
} from "../../components/shared/AppLayout/notification/notificationSlice";
import { getQueryParam } from "../../utils/getQueryParam";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();

  const strictModeEffectRan = useRef(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [jwt, setJwt] = useState(false);
  const [auth, setAuth] = useState(false);
  const [sub, setSub] = useState(false);
  const [urlToken, setUrlToken] = useState(false);
  const [username, setUsername] = useState("user");
  const [roles, setRoles] = useState([]);
  const [isEmployee, setIsEmployee] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [status, setStatus] = useState("user");

  const [refresh] = useRefreshMutation();
  const [signUpReq] = useSignUpMutation();
  const [resendVerificationEmailReq] = useResendVerificationEmailMutation();
  const [loginReq] = useLoginMutation();
  const [logoutReq] = useLogoutMutation();
  const [sendForgotPasswordEmailReq] = useSendForgotPasswordEmailMutation();
  const [submitForgotPasswordCodeReq] = useSubmitForgotPasswordCodeMutation();
  const [updatePasswordReq] = useUpdatePasswordMutation();
  const [updateStripeCheckoutSessionReq] =
    useUpdateStripeCheckoutSessionMutation();

  // helpers
  const authenticate = useCallback(
    (token) => {
      // set access token in redux
      dispatch(setCredentials({ accessToken: token }));
      setJwt(true);
      setAuth(true);
      const decoded = jwtDecode(token);
      const { username, roles, activeSubscription } = decoded.UserInfo;
      setUsername(username);
      setRoles(roles);
      setSub(activeSubscription);
      setIsEmployee(roles?.includes("employee"));
      setIsAdmin(roles?.includes("admin"));
      if (isEmployee) setStatus("employee");
      if (isAdmin) setStatus("admin");
    },
    [isEmployee, isAdmin, dispatch]
  );

  const unauthenticate = () => {
    // clear access token from redux
    dispatch(clearCredentials());
    setJwt(false);
    setAuth(false);
    setUsername("user");
    setRoles([]);
    setSub(false);
    setIsEmployee(false);
    setIsAdmin(false);
    setStatus("user");
  };

  const verifyRefreshToken = useCallback(async () => {
    setIsLoading(true);
    try {
      const { accessToken } = await refresh().unwrap();
      authenticate(accessToken);
    } catch (err) {
      if (err.status === 404 && err.data?.message === "jwt cookie not found") {
        setJwt(false);
      }
    } finally {
      setIsLoading(false);
    }
  }, [refresh, setIsLoading, authenticate, setJwt]);

  // persist authenticated status on refresh
  useEffect(() => {
    // if strict mode first render has happened (only happens in dev)
    if (strictModeEffectRan.current || process.env.NODE_ENV !== "development") {
      verifyRefreshToken();
    }

    return () => {
      strictModeEffectRan.current = true;
    };
  }, [refresh, dispatch, authenticate, verifyRefreshToken]);

  // if redirected from 3rd party auth provider (Google)
  useEffect(() => {
    // extract token from url
    const tokenFromURL = getQueryParam("token");
    if (tokenFromURL) {
      authenticate(tokenFromURL);
      setUrlToken(true);
    }
  }, [dispatch, authenticate]);

  const signUp = async (username, email, password) => {
    setIsLoading(true);
    try {
      // call api
      const data = await signUpReq({ username, email, password }).unwrap();
      dispatch(
        setNotification({
          type: "success",
          message: data?.message,
          additionalFields: {
            username: data?.username,
            email: data?.email,
          },
        })
      );
    } catch (err) {
      dispatch(
        setNotification({
          type: "error",
          message: err.data?.message,
        })
      );
      // kick the can down the road
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const resendVerificationEmail = async (email) => {
    setIsLoading(true);
    try {
      const data = await resendVerificationEmailReq({ email }).unwrap();
      dispatch(
        setNotification({
          type: "success",
          message: data?.message,
          additionalFields: {
            username: data?.username,
            email: data?.email,
          },
        })
      );
    } catch (err) {
      dispatch(
        setNotification({
          type: "error",
          message: err.data?.message,
          additionalFields: {
            username: err.data?.username,
            email: err.data?.email,
          },
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  // todo: change to username or email
  const login = async (credential, password) => {
    setIsLoading(true);
    try {
      // call api
      const { accessToken } = await loginReq({ credential, password }).unwrap();
      dispatch(clearNotification());
      authenticate(accessToken);
    } catch (err) {
      dispatch(
        setNotification({
          type: "error",
          message: err.data?.message,
          // messageVerbose: err.data?.messageVerbose,
          additionalFields: {
            username: err.data?.username,
            email: err.data?.email,
          },
        })
      );
      // kick the can down the road
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    setIsLoading(true);
    try {
      // call api
      await logoutReq().unwrap();
      unauthenticate();
    } catch (err) {
      dispatch(clearNotification());
      // kick the can down the road
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const sendForgotPasswordEmail = async (email) => {
    setIsLoading(true);
    let data;
    try {
      // call api
      data = await sendForgotPasswordEmailReq({ email }).unwrap();
      dispatch(
        setNotification({
          type: "success",
          message: data?.message,
          additionalFields: {
            username: data?.username,
            email: data?.email,
          },
        })
      );
    } catch (err) {
      dispatch(
        setNotification({
          type: "error",
          message: err.data?.message,
          additionalFields: {
            email: err.data?.email,
          },
        })
      );
    } finally {
      setIsLoading(false);
      return data;
    }
  };

  const submitForgotPasswordCode = async (code) => {
    setIsLoading(true);
    try {
      // call api
      const { accessToken } = await submitForgotPasswordCodeReq({
        code,
      }).unwrap();
      dispatch(clearNotification());
      dispatch(setCredentials({ accessToken }));
      setAuth(true);
      setIsResettingPassword(true);
    } catch (err) {
      dispatch(
        setNotification({
          type: "error",
          message: err.data?.message,
          additionalFields: {
            email: err.data?.email,
          },
        })
      );
      // kick the can down the road
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const resetPassword = async (password) => {
    setIsLoading(true);
    try {
      // call api
      const data = await updatePasswordReq({ password }).unwrap();
      dispatch(
        setNotification({
          type: "success",
          message: data?.message,
          additionalFields: {
            username: data?.username,
          },
        })
      );
    } catch (err) {
      dispatch(
        setNotification({
          type: "error",
          message: "unable to reset password",
        })
      );
      // kick the can down the road
      throw err;
    } finally {
      unauthenticate();
      setIsResettingPassword(false);
      setIsLoading(false);
    }
  };

  const createCheckoutSession = async () => {
    try {
      // call api
      const result = await updateStripeCheckoutSessionReq({
        lookup_key: stripeLookupKey,
      }).unwrap();
      if (result && result.url) {
        window.location.href = result.url; // redirect to the Stripe Checkout session
      }
    } catch (err) {
      console.error("Failed to create Stripe Checkout session:", err);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        jwt,
        auth,
        setAuth,
        sub,
        setSub,
        urlToken,
        username,
        roles,
        isEmployee,
        isAdmin,
        status,
        isLoading,
        signUp,
        resendVerificationEmail,
        login,
        logout,
        sendForgotPasswordEmail,
        submitForgotPasswordCode,
        isResettingPassword,
        resetPassword,
        createCheckoutSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
