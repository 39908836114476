import React, { useState } from "react";
import PosMorphologyItem from "./PosMorphologyItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./MorphologyItem.module.css";

const MorphologyItem = ({
  attributeName,
  attributeColor,
  attributeValue,
  attributeNameDescription,
  attributeValueDescription,
  selected,
}) => {
  const [showingValueDescription, setShowingDescription] = useState(false);

  let content;

  if (attributeName === "part of speech") {
    content = (
      <PosMorphologyItem
        attributeName={attributeName}
        attributeColor={attributeColor}
        attributeValue={attributeValue}
        attributeNameDescription={attributeNameDescription}
        selected={selected}
      />
    );
  } else {
    content = (
      <div className={styles.container}>
        {showingValueDescription ? (
          <>
            <section
              className={
                selected
                  ? styles.descriptionContainer__selected
                  : styles.descriptionContainer
              }
            >
              <div className={styles.itemContainer}>
                <div className={styles.itemInfoContainer}>
                  <p className={`${attributeColor} ${styles.attributeName}`}>
                    {attributeName}:{" "}
                  </p>
                  <FontAwesomeIcon
                    className={styles.icon}
                    icon={faChevronDown}
                    onClick={() => setShowingDescription(false)}
                  />
                </div>
                <p className={styles.attributeNameDescription}>
                  {attributeNameDescription}
                </p>
              </div>
              {attributeValue && (
                <p className={styles.valueDescription}>
                  <>
                    {attributeValueDescription ? (
                      <>
                        <span className={styles.valueSpan}>
                          {attributeValue}
                        </span>{" "}
                        - {attributeValueDescription}
                      </>
                    ) : (
                      <>
                        <span className={styles.valueSpan}>
                          {attributeValue}
                        </span>
                      </>
                    )}
                  </>
                </p>
              )}
            </section>
          </>
        ) : (
          <div
            className={selected ? styles.itemRow__selected : styles.itemRow}
            onClick={() => setShowingDescription(true)}
          >
            <p className={styles.item}>
              <span
                className={`${attributeColor}
                ${styles.attributeName}
                `}
              >
                {attributeName}:{" "}
              </span>
              {attributeValue}
            </p>
            <div className={styles.infoIcon}>
              {showingValueDescription ? (
                <FontAwesomeIcon icon={faChevronDown} />
              ) : (
                <FontAwesomeIcon icon={faChevronRight} />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  return content;
};

export default MorphologyItem;
