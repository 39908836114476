import React, { useState, useRef, useEffect } from "react";
import { useAutoCreateNewSentenceMutation } from "../adminApiSlice";
import DropZone from "./DropZone";
import LoadMsg from "../../../components/shared/LoadMsg/LoadMsg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../AdminIndex.module.css";

const AutoCreateMultipleNewSentences = () => {
  const successRef = useRef(null);
  const errRef = useRef(null);
  const [showingForm, setShowingForm] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [autoCreateNewSentence, { isLoading }] =
    useAutoCreateNewSentenceMutation();

  const clearMsgs = () => {
    setSuccessMsg("");
    setErrMsg("");
  };

  useEffect(() => {
    clearMsgs();
  }, []);

  const handleSubmit = async (jsonContent) => {
    try {
      setSuccessMsg("");
      console.log("Submitting data:", jsonContent);
      const { message, isError } = await autoCreateNewSentence(
        jsonContent
      ).unwrap();
      if (isError) {
        throw new Error(message);
      }
      setErrMsg("");
      setSuccessMsg(message);
      successRef.current?.focus();
    } catch (err) {
      console.error("Submission error:", err);
      setErrMsg(err?.data?.message || err.message);
      errRef.current?.focus();
    }
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleFilesDropped = async (files) => {
    console.log("Files dropped:", files);
    const validFiles = files.filter((file) => file.name.endsWith(".json"));

    const fileDataPromises = validFiles.map((file) =>
      readFileContent(file).then((content) => {
        try {
          const jsonContent = JSON.parse(content);
          return { name: file.name, jsonContent };
        } catch (error) {
          throw new Error(`Failed to parse JSON content of file: ${file.name}`);
        }
      })
    );

    try {
      const fileData = await Promise.all(fileDataPromises);
      for (const [index, file] of fileData.entries()) {
        await handleSubmit(file.jsonContent);
        // Add throttling with variable delay to observe its effect
        await delay(500 + index * 100);
      }
      setSuccessMsg("All files processed successfully");
    } catch (error) {
      console.error("Error processing files:", error.message || error);
      setErrMsg(error.message);
    }
  };

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file);
    });
  };

  return (
    <section className={styles.section}>
      <div
        onClick={() => setShowingForm(!showingForm)}
        className={styles.headerRow}
      >
        {showingForm ? (
          <FontAwesomeIcon className={styles.icon} icon={faChevronDown} />
        ) : (
          <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
        )}
        <h2 className={styles.h2}>
          drag and drop:
          <br />
          POST /auto/sentence
        </h2>
      </div>
      {showingForm && (
        <>
          <DropZone onFilesDropped={handleFilesDropped} />
          {isLoading && (
            <div className={styles.loadingContainer}>
              <LoadMsg msg="creating sentence..." size="1x" />
            </div>
          )}
          {successMsg && (
            <p ref={successRef} style={{ color: "#109868" }}>
              {successMsg}
            </p>
          )}
          {errMsg && (
            <p ref={errRef} style={{ color: "red" }}>
              {errMsg}
            </p>
          )}
        </>
      )}
    </section>
  );
};
export default AutoCreateMultipleNewSentences;
