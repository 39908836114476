// src/components/Home/HomeV3/Demo/demo-data.js

export const SENTENCES = {
  ids: ["1"],
  entities: {
    1: {
      _id: "1",
      position: 1,
      english:
        "Acquire languages in the same way that humans have done for centuries.",
      spanish:
        "Adquiera idiomas de la misma manera que los humanos lo han hecho durante siglos.",
    },
  },
};

export const WORD_INSTANCES = {
  ids: [
    // Spanish Word Instances
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    // English Word Instances
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
  ],
  entities: {
    // Spanish Word Instances
    1: {
      _id: "1",
      sentence: "1",
      position: 1,
      text: "Adquiera",
      translation: "Acquire",
      language: "Spanish",
      stem: {
        _id: "adquier",
        text: "Adquier",
      },
      ending1: {
        _id: "a1",
        text: "a",
      },
      ending2: {},
      ending3: {},
    },
    2: {
      _id: "2",
      sentence: "1",
      position: 2,
      text: "idiomas",
      translation: "languages",
      language: "Spanish",
      stem: {
        _id: "idioma",
        text: "idioma",
      },
      ending1: {
        _id: "s1",
        text: "s",
      },
      ending2: {},
      ending3: {},
    },
    3: {
      _id: "3",
      sentence: "1",
      position: 3,
      text: "de",
      translation: "of",
      language: "Spanish",
      stem: {
        _id: "de",
        text: "de",
      },
      ending1: {},
      ending2: {},
      ending3: {},
    },
    4: {
      _id: "4",
      sentence: "1",
      position: 4,
      text: "la",
      translation: "the",
      language: "Spanish",
      stem: {
        _id: "l",
        text: "l",
      },
      ending1: {
        _id: "a",
        text: "a",
      },
      ending2: {},
      ending3: {},
    },
    5: {
      _id: "5",
      sentence: "1",
      position: 5,
      text: "misma",
      translation: "same",
      language: "Spanish",
      stem: {
        _id: "mism",
        text: "mism",
      },
      ending1: {
        _id: "a",
        text: "a",
      },
      ending2: {},
      ending3: {},
    },
    6: {
      _id: "6",
      sentence: "1",
      position: 6,
      text: "manera",
      translation: "way",
      language: "Spanish",
      stem: {
        _id: "manera",
        text: "manera",
      },
      ending1: {},
      ending2: {},
      ending3: {},
    },
    7: {
      _id: "7",
      sentence: "1",
      position: 7,
      text: "que",
      translation: "that",
      language: "Spanish",
      stem: {
        _id: "que",
        text: "que",
      },
      ending1: {},
      ending2: {},
      ending3: {},
    },
    8: {
      _id: "8",
      sentence: "1",
      position: 8,
      text: "los",
      translation: "the",
      language: "Spanish",
      stem: {
        _id: "l",
        text: "l",
      },
      ending1: {
        _id: "o",
        text: "o",
      },
      ending2: {
        _id: "s1",
        text: "s",
      },
      ending3: {},
    },
    9: {
      _id: "9",
      sentence: "1",
      position: 9,
      text: "humanos",
      translation: "humans",
      language: "Spanish",
      stem: {
        _id: "humano",
        text: "humano",
      },
      ending1: {
        _id: "s1",
        text: "s",
      },
      ending2: {},
      ending3: {},
    },
    10: {
      _id: "10",
      sentence: "1",
      position: 10,
      text: "lo",
      translation: "it",
      language: "Spanish",
      stem: {
        _id: "lo",
        text: "lo",
      },
      ending1: {},
      ending2: {},
      ending3: {},
    },
    11: {
      _id: "11",
      sentence: "1",
      position: 11,
      text: "han",
      translation: "have",
      language: "Spanish",
      stem: {
        _id: "han",
        text: "han",
      },
      ending1: {},
      ending2: {},
      ending3: {},
    },
    12: {
      _id: "12",
      sentence: "1",
      position: 12,
      text: "hecho",
      translation: "done",
      language: "Spanish",
      stem: {
        _id: "hecho",
        text: "hecho",
      },
      ending1: {},
      ending2: {},
      ending3: {},
    },
    13: {
      _id: "13",
      sentence: "1",
      position: 13,
      text: "durante",
      translation: "during",
      language: "Spanish",
      stem: {
        _id: "durante",
        text: "durante",
      },
      ending1: {},
      ending2: {},
      ending3: {},
    },
    14: {
      _id: "14",
      sentence: "1",
      position: 14,
      text: "siglos",
      translation: "centuries",
      language: "Spanish",
      stem: {
        _id: "siglo",
        text: "siglo",
      },
      ending1: {
        _id: "s1",
        text: "s",
      },
      ending2: {},
      ending3: {},
    },

    // English Word Instances
    15: {
      _id: "15",
      sentence: "1",
      position: 1,
      text: "Acquire",
      translation: "Adquiera",
      language: "English",
      stem: {
        _id: "acquire",
        text: "Acquire",
      },
      ending1: {},
      ending2: {},
      ending3: {},
    },
    16: {
      _id: "16",
      sentence: "1",
      position: 2,
      text: "languages",
      translation: "idiomas",
      language: "English",
      stem: {
        _id: "language",
        text: "language",
      },
      ending1: {
        _id: "s",
        text: "s",
      },
      ending2: {},
      ending3: {},
    },
    17: {
      _id: "17",
      sentence: "1",
      position: 3,
      text: "in",
      translation: "en",
      language: "English",
      stem: {
        _id: "in",
        text: "in",
      },
      ending1: {},
      ending2: {},
      ending3: {},
    },
    18: {
      _id: "18",
      sentence: "1",
      position: 4,
      text: "the",
      translation: "la",
      language: "English",
      stem: {
        _id: "the",
        text: "the",
      },
      ending1: {},
      ending2: {},
      ending3: {},
    },
    19: {
      _id: "19",
      sentence: "1",
      position: 5,
      text: "same",
      translation: "misma",
      language: "English",
      stem: {
        _id: "same",
        text: "same",
      },
      ending1: {},
      ending2: {},
      ending3: {},
    },
    20: {
      _id: "20",
      sentence: "1",
      position: 6,
      text: "way",
      translation: "manera",
      language: "English",
      stem: {
        _id: "way",
        text: "way",
      },
      ending1: {},
      ending2: {},
      ending3: {},
    },
    21: {
      _id: "21",
      sentence: "1",
      position: 7,
      text: "that",
      translation: "que",
      language: "English",
      stem: {
        _id: "that",
        text: "that",
      },
      ending1: {},
      ending2: {},
      ending3: {},
    },
    22: {
      _id: "22",
      sentence: "1",
      position: 8,
      text: "humans",
      translation: "humanos",
      language: "English",
      stem: {
        _id: "human",
        text: "human",
      },
      ending1: {
        _id: "s",
        text: "s",
      },
      ending2: {},
      ending3: {},
    },
    23: {
      _id: "23",
      sentence: "1",
      position: 9,
      text: "have",
      translation: "han",
      language: "English",
      stem: {
        _id: "have",
        text: "have",
      },
      ending1: {},
      ending2: {},
      ending3: {},
    },
    24: {
      _id: "24",
      sentence: "1",
      position: 11,
      text: "for",
      translation: "por",
      language: "English",
      stem: {
        _id: "for",
        text: "for",
      },
      ending1: {},
      ending2: {},
      ending3: {},
    },
    25: {
      _id: "26",
      sentence: "1",
      position: 12,
      text: "centuries",
      translation: "siglos",
      language: "English",
      stem: {
        _id: "centur",
        text: "centur",
      },
      ending1: {
        _id: "ies",
        text: "ies",
      },
      ending2: {},
      ending3: {},
    },
    // Add more English word instances if needed
  },
};

const user = "demoUser";
const state = "new";
const languageSpanish = "Spanish";
const languageEnglish = "English";

export const VOCABULARIES = {
  ids: [
    // Spanish Vocabularies
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    // English Vocabularies
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
  ],
  entities: {
    // Spanish Vocabularies
    1: {
      _id: "1",
      user,
      state,
      language: languageSpanish,
      fragment: "adquier",
    },
    2: {
      _id: "2",
      user,
      state,
      language: languageSpanish,
      fragment: "a1",
    },
    3: {
      _id: "3",
      user,
      state,
      language: languageSpanish,
      fragment: "idioma",
    },
    4: {
      _id: "4",
      user,
      state,
      language: languageSpanish,
      fragment: "s1",
    },
    5: {
      _id: "5",
      user,
      state,
      language: languageSpanish,
      fragment: "de",
    },
    6: {
      _id: "6",
      user,
      state,
      language: languageSpanish,
      fragment: "l",
    },
    7: {
      _id: "7",
      user,
      state,
      language: languageSpanish,
      fragment: "a",
    },
    8: {
      _id: "8",
      user,
      state,
      language: languageSpanish,
      fragment: "mism",
    },
    9: {
      _id: "9",
      user,
      state,
      language: languageSpanish,
      fragment: "manera",
    },
    10: {
      _id: "10",
      user,
      state,
      language: languageSpanish,
      fragment: "que",
    },
    11: {
      _id: "10",
      user,
      state,
      language: languageSpanish,
      fragment: "l",
    },
    12: {
      _id: "11",
      user,
      state,
      language: languageSpanish,
      fragment: "o",
    },
    13: {
      _id: "12",
      user,
      state,
      language: languageSpanish,
      fragment: "s1",
    },
    14: {
      _id: "14",
      user,
      state,
      language: languageSpanish,
      fragment: "humano",
    },
    15: {
      _id: "15",
      user,
      state,
      language: languageSpanish,
      fragment: "lo",
    },
    16: {
      _id: "16",
      user,
      state,
      language: languageSpanish,
      fragment: "han",
    },
    18: {
      _id: "18",
      user,
      state,
      language: languageSpanish,
      fragment: "hecho",
    },
    19: {
      _id: "19",
      user,
      state,
      language: languageSpanish,
      fragment: "durante",
    },
    20: {
      _id: "20",
      user,
      state,
      language: languageSpanish,
      fragment: "siglo",
    },
    21: {
      _id: "21",
      user,
      state,
      language: languageSpanish,
      fragment: "s1",
    },
    // English Vocabularies
    22: {
      _id: "22",
      user,
      state,
      language: languageEnglish,
      fragment: "acquire",
    },
    23: {
      _id: "23",
      user,
      state,
      language: languageEnglish,
      fragment: "language",
    },
    24: {
      _id: "24",
      user,
      state,
      language: languageEnglish,
      fragment: "s",
    },
    25: {
      _id: "25",
      user,
      state,
      language: languageEnglish,
      fragment: "in",
    },
    26: {
      _id: "26",
      user,
      state,
      language: languageEnglish,
      fragment: "the",
    },
    27: {
      _id: "27",
      user,
      state,
      language: languageEnglish,
      fragment: "same",
    },
    28: {
      _id: "28",
      user,
      state,
      language: languageEnglish,
      fragment: "way",
    },
    29: {
      _id: "29",
      user,
      state,
      language: languageEnglish,
      fragment: "that",
    },
    30: {
      _id: "30",
      user,
      state,
      language: languageEnglish,
      fragment: "human",
    },
    31: {
      _id: "31",
      user,
      state,
      language: languageEnglish,
      fragment: "s",
    },
    32: {
      _id: "32",
      user,
      state,
      language: languageEnglish,
      fragment: "have",
    },
    33: {
      _id: "33",
      user,
      state,
      language: languageEnglish,
      fragment: "for",
    },
    34: {
      _id: "34",
      user,
      state,
      language: languageEnglish,
      fragment: "centur",
    },
    35: {
      _id: "35",
      user,
      state,
      language: languageEnglish,
      fragment: "ies",
    },

    // Add more English vocabularies if needed
  },
};
