import { apiSlice } from "../../app/api/apiSlice";

export const profileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserProfile: builder.query({
      query: () => ({
        url: "/users/profile",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      providesTags: ["User"],
    }),
    updateUsername: builder.mutation({
      query: ({ username }) => ({
        url: "/users/username",
        method: "PATCH",
        body: { username },
      }),
      invalidatesTags: ["User"],
    }),
    updatePassword: builder.mutation({
      query: ({ password }) => ({
        url: "/users/password",
        method: "PATCH",
        body: { password },
      }),
    }),
    updateLastRead: builder.mutation({
      query: ({ bookId, chapter, sentenceId }) => ({
        url: "users/last-read",
        method: "PATCH",
        body: { bookId, chapter, sentenceId },
      }),
      invalidatesTags: ["User"],
    }),
    updateAllUserBookProgress: builder.mutation({
      query: () => ({
        url: "/vocabularies/user-book-progress",
        method: "PUT",
      }),
      invalidatesTags: ["User"],
    }),
    updateUserKnownFragmentCount: builder.mutation({
      query: ({ languageId }) => ({
        url: "/vocabularies/user-known-fragment-count",
        method: "PATCH",
        body: { languageId },
      }),
      invalidatesTags: ["User"],
    }),
    updateUserSetting: builder.mutation({
      query: ({ setting, value }) => ({
        url: "/users/settings",
        method: "PATCH",
        body: { setting, value },
      }),
      invalidatesTags: ["User"],
    }),
    updateUserHasLoggedIn: builder.mutation({
      query: ({ value }) => ({
        url: "/users/has-logged-in",
        method: "PATCH",
        body: { value },
      }),
      invalidatesTags: ["User"],
    }),
    updateUserHasSeenTutorial: builder.mutation({
      query: ({ value }) => ({
        url: "/users/has-seen-tutorial",
        method: "PATCH",
        body: { value },
      }),
      invalidatesTags: ["User"],
    }),
    updateStripeCheckoutSession: builder.mutation({
      query: ({ lookup_key }) => ({
        url: "/stripe/create-checkout-session",
        method: "POST",
        body: { lookup_key },
      }),
      invalidatesTags: ["User"],
    }),
    createPortalSession: builder.mutation({
      query: ({ customerId }) => ({
        url: "/stripe/create-portal-session",
        method: "POST",
        body: { customerId },
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useGetUserProfileQuery,
  useUpdatePasswordMutation,
  useUpdateUsernameMutation,
  useUpdateLastReadMutation,
  useUpdateAllUserBookProgressMutation,
  useUpdateUserKnownFragmentCountMutation,
  useUpdateUserSettingMutation,
  useUpdateUserHasLoggedInMutation,
  useUpdateUserHasSeenTutorialMutation,
  useUpdateStripeCheckoutSessionMutation,
  useCreatePortalSessionMutation,
} = profileApiSlice;
