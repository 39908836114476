import React, { useRef, useState, useEffect } from "react";
import { useCheckSentencesHealthMutation } from "./adminApiSlice";
import LoadMsg from "../../components/shared/LoadMsg/LoadMsg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./AdminIndex.module.css";

const CheckSentencesHealth = () => {
  const errRef = useRef();
  const successRef = useRef();

  const [showingForm, setShowingForm] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [successMsgDetails, setSuccessMsgDetails] = useState([]);

  const [bookTitle, setBookTitle] = useState("");

  const [checkSentencesHealth, { isLoading }] =
    useCheckSentencesHealthMutation();

  const clearMsgs = () => {
    setErrMsg("");
    setSuccessMsg("");
  };

  useEffect(() => {
    clearMsgs();
  }, [bookTitle]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearMsgs();
    console.log(bookTitle);
    try {
      const { message, total, isError } = await checkSentencesHealth({
        bookTitle,
      }).unwrap();
      if (isError) {
        throw new Error(message);
      }
      setSuccessMsg(`Total missing sentences: ${total}`);
      setSuccessMsgDetails(message);
      successRef.current?.focus();
    } catch (err) {
      console.error(err);
      console.log(err?.message);
      setErrMsg(err?.data?.message || err.message);
      errRef.current?.focus();
    }
  };

  let content;

  if (isLoading) {
    content = (
      <div className={styles.loadingContainer}>
        <LoadMsg msg="checking sentences health" size="1x" />
      </div>
    );
  } else {
    content = (
      <section className={styles.section}>
        <div
          onClick={() => setShowingForm(!showingForm)}
          className={styles.headerRow}
        >
          {showingForm ? (
            <FontAwesomeIcon className={styles.icon} icon={faChevronDown} />
          ) : (
            <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
          )}
          <h2 className={styles.h2}>GET /sentences/check-health</h2>
        </div>
        {showingForm && (
          <>
            {errMsg && (
              <p
                ref={errRef}
                className={styles.errMsg}
                aria-live="assertive"
                role="alert"
              >
                {errMsg}
              </p>
            )}

            {successMsg && (
              <>
                <p
                  ref={successRef}
                  className={styles.successMsg}
                  aria-live="assertive"
                  role="alert"
                >
                  {successMsg}
                </p>
                {successMsgDetails.length > 0 && (
                  <>
                    <p className={styles.successMsgDetails}>
                      The following positions were not found:
                    </p>
                    <ol className={styles.successMsgDetails}>
                      {successMsgDetails?.map((detail) => (
                        <li>{detail}</li>
                      ))}
                    </ol>
                  </>
                )}
              </>
            )}

            <form className="form__admin" onSubmit={handleSubmit}>
              {/* title */}
              <div className="form__col">
                <div className="form__row">
                  <label className="form__label" htmlFor="bookTitle">
                    bookTitle: *
                  </label>
                </div>
                <div className="form__row grow">
                  <input
                    type="text"
                    className="form__input grow"
                    id="bookTitle"
                    value={bookTitle}
                    onChange={(e) => setBookTitle(e.target.value)}
                    autoComplete="off"
                    required
                    autoFocus
                    tabIndex="1"
                  />
                </div>
              </div>

              {/* submit button by default since only 1 button in this form */}
              <button className="form__submitButton" tabIndex="5">
                check sentences health
              </button>
            </form>
          </>
        )}
      </section>
    );
  }

  return content;
};

export default CheckSentencesHealth;
