import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import TopBanner from "./TopBanner/TopBanner";
import MainNav from "./MainNav/MainNav";
import Footer from "./Footer/Footer";

import smoothScrollToAnchor from "../../../utils/smoothScrollToAnchor";

const MainLayout = () => {
  const { pathname, hash } = useLocation();

  // for anchor scrolling
  useEffect(() => {
    if (!hash) {
      window.scrollTo(0, 0);
    } else {
      smoothScrollToAnchor();
    }
  }, [pathname, hash]);

  return (
    <>
      {/* <TopBanner /> */}
      <MainNav />
      <Outlet />
      <Footer />
    </>
  );
};

export default MainLayout;
