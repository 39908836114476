import React, { useRef, useState } from "react";
import { useResetUserVocabularyMutation } from "./adminApiSlice";
import LoadMsg from "../../components/shared/LoadMsg/LoadMsg";
import FormErrMsg from "../../components/shared/FormErrMsg/FormErrMsg";
import SuccessMsg from "../../components/shared/SuccessMsg/SuccessMsg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./AdminIndex.module.css";

const ResetUserVocabulary = () => {
  const errRef = useRef();
  const successRef = useRef();

  const [confirmation, setConfirmation] = useState();
  const [username, setUsername] = useState();
  const [showingForm, setShowingForm] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [resetUserVocabulary, { isLoading }] = useResetUserVocabularyMutation();

  const clearMsgs = () => {
    setErrMsg("");
    setSuccessMsg("");
  };

  const canSubmit = confirmation === `Reset ${username}'s vocabulary`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (canSubmit) {
      clearMsgs();
      try {
        const { message, isError } = await resetUserVocabulary({
          username,
        }).unwrap();
        if (isError) {
          throw new Error(message);
        }
        setSuccessMsg(message);
        successRef.current?.focus();
      } catch (err) {
        console.error(err);
        console.log(err?.message);
        setErrMsg(err?.data?.message || err.message);
        errRef.current?.focus();
      }
    } else {
      setSuccessMsg("");
      setErrMsg("confirm first");
      errRef.current?.focus();
    }
  };

  let content;

  if (isLoading) {
    content = (
      <div className={styles.loadingContainer}>
        <LoadMsg msg="resetting user vocabulary" size="1x" />
      </div>
    );
  } else {
    content = (
      <section className={styles.section}>
        <div
          onClick={() => setShowingForm(!showingForm)}
          className={styles.headerRow}
        >
          {showingForm ? (
            <FontAwesomeIcon className={styles.icon} icon={faChevronDown} />
          ) : (
            <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
          )}
          <h2 className={styles.h2}>
            PATCH /vocabularies/reset-user-vocabulary
          </h2>
        </div>
        {showingForm && (
          <>
            {/* Error Message */}
            {errMsg && (
              <FormErrMsg
                elements={[
                  <p ref={errRef} aria-live="assertive" role="alert">
                    {errMsg}
                  </p>,
                ]}
              />
            )}

            {/* Success Message */}
            {successMsg && (
              <SuccessMsg
                elements={[
                  <p ref={successRef} aria-live="assertive" role="alert">
                    {successMsg}
                  </p>,
                ]}
              />
            )}

            <form className="form__admin" onSubmit={handleSubmit}>
              {/* title */}
              <div className="form__col">
                {/* username */}
                <div className="form__row">
                  <label className="form__label" htmlFor="username">
                    Username of the profile you wish to reset the vocabulary
                    for: *
                  </label>
                </div>
                <div className="form__row grow">
                  <input
                    type="text"
                    className="form__input grow"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    autoComplete="off"
                    autoFocus
                    required
                    tabIndex="1"
                  />
                </div>

                {/* confirmation */}
                <div className="form__row">
                  <label className="form__label" htmlFor="confirmation">
                    {username
                      ? `confirm by typing "Reset ${username}'s vocabulary": *`
                      : "confirm: *"}
                  </label>
                </div>
                <div className="form__row grow">
                  <input
                    type="text"
                    className="form__input grow"
                    id="confirmation"
                    value={confirmation}
                    onChange={(e) => setConfirmation(e.target.value)}
                    autoComplete="off"
                    required
                    tabIndex="1"
                  />
                </div>
              </div>

              {/* submit button by default since only 1 button in this form */}
              <button
                className={
                  canSubmit
                    ? "form__submitButton--warning"
                    : "form__submitButton--disabled"
                }
                tabIndex="5"
              >
                {username
                  ? `RESET ${username}'s VOCABULARY`
                  : "RESET VOCABULARY"}
              </button>
            </form>
          </>
        )}
      </section>
    );
  }

  return content;
};

export default ResetUserVocabulary;
