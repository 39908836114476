import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import { useReportIssueMutation } from "../../../../../features/contact/contactApiSlice";
import { useGetUserProfileQuery } from "../../../../../features/profile/profileApiSlice";

import LoadMsg from "../../../LoadMsg/LoadMsg";
import FormErrMsg from "../../../FormErrMsg/FormErrMsg";
import SuccessMsg from "../../../SuccessMsg/SuccessMsg";

import useTitle from "../../../../../hooks/useTitle";

import styles from "./ReportIssue.module.css";

const ReportIssue = () => {
  useTitle("Report Issue");

  const [reportIssue, { isLoading }] = useReportIssueMutation();

  // todo: consider providing userEmail in AuthContext
  const { data: userProfile } = useGetUserProfileQuery("userProfile");

  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");

  const successRef = useRef();
  const [successMsg, setSuccessMsg] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    email: userProfile?.email ? userProfile.email : "",
    subject: "",
    message: "",
  });

  const [validEmail, setValidEmail] = useState(false);

  useEffect(() => {
    setValidEmail(isEmail(formData.email));
  }, [formData.email]);

  const canSubmit =
    [formData.name, validEmail, formData.subject, formData.message].every(
      Boolean
    ) && !isLoading;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (canSubmit) {
      try {
        const data = await reportIssue(formData).unwrap();
        // reset form subject & message
        setFormData((prevFormData) => ({
          ...prevFormData,
          subject: "",
          message: "",
        }));
        setSuccessMsg(data?.message);
        successRef.current?.focus();
      } catch (err) {
        setErrMsg(err.data?.message);
        errRef.current?.focus();
      }
    }
  };

  let content;

  if (isLoading) {
    content = <LoadMsg msg="loading" size="2x" />;
  } else {
    content = (
      <section className={styles.container}>
        <h2 className={styles.h2}>Report an Issue</h2>

        {/* Error Message */}
        {errMsg && (
          <FormErrMsg
            elements={[
              <p ref={errRef} aria-live="assertive" role="alert">
                {errMsg}
              </p>,
            ]}
          />
        )}

        {/* Success Message */}
        {successMsg && (
          <SuccessMsg
            elements={[
              <p ref={successRef} aria-live="assertive" role="alert">
                {successMsg}
              </p>,
            ]}
          />
        )}

        <form className="form" onSubmit={handleSubmit}>
          {/* Name */}
          <div className="form__col">
            <div className="form__row">
              <label className="form__label" htmlFor="name">
                Name:
              </label>
            </div>
            <div className="form__row">
              <input
                className="form__input"
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                autoFocus
                tabIndex="1"
              />
            </div>
          </div>

          {/* Email */}
          <div className="form__col">
            <div className="form__row">
              <label className="form__label" htmlFor="email">
                Email:
              </label>
            </div>
            <p className="form__requirements">
              valid email required (e.g., someone@example.com)
            </p>
            <div className="form__row">
              <input
                className={`form__input
                  ${
                    formData.email
                      ? validEmail
                        ? "form__input--valid"
                        : "form__input--invalid"
                      : ""
                  }
                  `}
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                tabIndex="2"
              />
            </div>
          </div>

          {/* Subject */}
          <div className="form__col">
            <div className="form__row">
              <label className="form__label" htmlFor="subject">
                Subject:
              </label>
            </div>
            <p className="form__requirements">
              What type of issue are you experiencing? (e.g., Subscribe, Sign
              Up)
            </p>
            <div className="form__row">
              <input
                className="form__input"
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                tabIndex="3"
              />
            </div>
          </div>

          {/* Message */}
          <div className="form__col">
            <div className="form__row">
              <label className="form__label" htmlFor="message">
                Message:
              </label>
            </div>
            <p className="form__requirements">
              Please provide a detailed description of your issue.
            </p>
            <div className="form__row">
              <textarea
                className="form__input"
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                tabIndex="4"
              />
            </div>
          </div>

          {/* Submit button */}
          <button
            className={
              canSubmit ? `form__submitButton` : `form__submitButton--disabled`
            }
            tabIndex="5"
          >
            Submit
          </button>
        </form>

        <p className={styles.reportIssue}>
          This form is for reporting issues you are experiencing within the
          application. If you would like to submit a general inquiry, please{" "}
          <Link to="/contact" className={`nowrap ${styles.link}`}>
            contact us
          </Link>{" "}
          instead.
        </p>
      </section>
    );
  }

  return (
    <main className="page-wrapper">
      <div className="grid-center">{content}</div>
    </main>
  );
};

export default ReportIssue;
