import React from "react";
import useTitle from "../../../../../hooks/useTitle";

import styles from "./Donate.module.css";

const Donate = () => {
  useTitle("Donate");

  const content = (
    <section className={styles.container}>
      <h2 className={styles.header}>Donate</h2>
      <article className={styles.article}>
        <p className={styles.p}>
          Iter Lexici is a very young startup looking to improve the general
          outcome of language learning. We are a team of two and are still
          aiming to generate enough revenue to work at this goal full time. If
          you should feel inclined to support our mission beyond a subscription,
          you may do so below.
        </p>
      </article>
      <div className={styles.donateContainer}>
        <a
          href="https://donate.stripe.com/00gaER6GU6o67bWbIJ"
          className={styles.donateLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Donate Here
        </a>
      </div>
    </section>
  );

  return (
    <main className="page-wrapper">
      <div className="grid-center">{content}</div>
    </main>
  );
};

export default Donate;
