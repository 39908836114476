// src/components/Home/HomeV3/HomeV3.js

import React, { useContext, useState, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../features/auth/AuthContext";
import Demo from "./Demo/Demo";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import styles from "./HomeV3.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const flagData = [
  {
    id: 1,
    src: "/img/flags/UK-English.png",
    alt: "English",
    emoji: "🇬🇧",
    language: "English",
  },
  {
    id: 2,
    src: "/img/flags/Spanish.png",
    alt: "Spanish",
    emoji: "🇪🇸",
    language: "Spanish",
  },
];

const Home = () => {
  const { auth } = useContext(AuthContext);

  // State to track language direction: true = English to Spanish, false = Spanish to English
  const [isEnglishToSpanish, setIsEnglishToSpanish] = useState(false);

  // State to manage transition classes
  const [isTransitioning, setIsTransitioning] = useState(false);

  // New state to track if language has been toggled automatically
  const [hasToggledLanguage, setHasToggledLanguage] = useState(false);

  // Create a ref for the languages button
  const languagesButtonRef = useRef(null);

  const toggleLanguageDirection = () => {
    if (isTransitioning) return; // Prevent multiple clicks during transition
    setIsTransitioning(true);
    // Start fade-out
    setTimeout(() => {
      setIsEnglishToSpanish((prev) => !prev);
      // Start fade-in after fade-out completes
      setTimeout(() => {
        setIsTransitioning(false);
      }, 300); // Duration should match the CSS transition duration
    }, 300); // Duration should match the CSS transition duration
  };

  // Callback to handle auto-click cycle completion
  const handleAutoClickComplete = useCallback(() => {
    if (!hasToggledLanguage) {
      if (languagesButtonRef.current) {
        languagesButtonRef.current.click(); // Simulate button click
      } else {
        toggleLanguageDirection(); // Fallback to direct toggle
      }
      setHasToggledLanguage(true);
    }
  }, [hasToggledLanguage, toggleLanguageDirection]);

  // Determine flag order based on language direction
  const orderedFlags = isEnglishToSpanish ? flagData : [...flagData].reverse();

  let content;

  content = (
    <section className={styles.hero}>
      {/* Background Image */}
      <img
        src="/img/dq-engravings/p010.jpg"
        alt="Background"
        className={styles.backgroundImage}
      />

      {/* Foreground Content */}
      <div className={styles.heroCol}>
        <h1 className={styles.heroHeading}>Iter Lexici</h1>
        <div className={styles.languagesContainer}>
          <button
            className={styles.languages}
            onClick={toggleLanguageDirection}
            role="button"
            tabIndex={0}
            ref={languagesButtonRef} // Attach the ref here
          >
            {orderedFlags.map((flag, index) => (
              <React.Fragment key={flag.id}>
                <div className={styles.languageItem}>
                  <div
                    className={styles.flag}
                    title={flag.alt}
                    aria-label={`Switch to ${flag.language}`}
                  >
                    {flag.emoji}
                  </div>
                </div>
                {index === 0 && (
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className={styles.arrow}
                  />
                )}
              </React.Fragment>
            ))}
          </button>
        </div>
      </div>

      {/* Demo Component with Transition Classes */}
      <div
        className={`${styles.hookContainer} ${
          isTransitioning ? styles.fadeOut : styles.fadeIn
        }`}
      >
        <Demo
          isEnglishToSpanish={isEnglishToSpanish}
          onAutoClickComplete={handleAutoClickComplete} // Pass the callback here
        />
      </div>

      {/* CTA Container with Transition */}
      <div
        className={`${styles.ctaContainer} ${
          isTransitioning ? styles.fadeOut : styles.fadeIn
        }`}
      >
        {!auth && (
          <Link to="/sign-up" className={styles.cta}>
            <p className={styles.heroP}>START READING</p>
          </Link>
        )}
      </div>
    </section>
  );

  return (
    <main
      className="page-wrapper"
      style={{ backgroundColor: "var(--PRIMARY-BGCOLOR)" }}
    >
      {content}
    </main>
  );
};

export default Home;
