import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

import styles from "./Footer.module.css";

const Footer = () => {
  useEffect(() => {
    // Check if Termly is loaded
    if (window.Termly) {
      window.Termly.initialize();
    }
  }, []);
  return (
    <footer>
      <div className={styles.grid}>
        <div className={styles.brandCol}>
          <Link to="/" className={styles.brandLink}>
            <p className={styles.brandName}>Iter Lexici</p>

            <p className={styles.brandSlogan}>Acquire Language</p>
          </Link>
          <ul className={styles.socialContainer}>
            <li className={styles.socialItem}>
              <a
                href="https://www.instagram.com/iterlexici/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  className={styles.socialIcon}
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCoLRCh267njuY_45fGM6pDw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faYoutube}
                  className={styles.socialIcon}
                />
              </a>
            </li>
          </ul>
        </div>

        <div className={styles.nav1Col}>
          <h2 className={styles.navHeader}>Company</h2>

          <ul className={styles.navList}>
            <li>
              <Link to="/about" className={styles.link}>
                About
              </Link>
            </li>
            <li>
              <Link to="/contact" className={styles.link}>
                Contact Us
              </Link>
            </li>
          </ul>
        </div>

        <div className={styles.nav2Col}>
          <h2 className={styles.navHeader}>Support</h2>

          <ul className={styles.navList}>
            <li>
              <Link to="/faq" className={styles.link}>
                FAQ
              </Link>
            </li>
            <li>
              <Link to="/report-issue" className={styles.link}>
                Report an issue
              </Link>
            </li>
            <li>
              <Link to="/donate" className={styles.link}>
                Donate
              </Link>
            </li>
          </ul>
        </div>

        <div className={styles.legalRow}>
          <ul className={styles.legalList}>
            <li>
              <a
                href="https://app.termly.io/policy-viewer/policy.html?policyUUID=fb9d52d9-4365-4672-8cda-b865757ea63d"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                Terms of Service
              </a>
            </li>
            <li>
              <a
                href="https://app.termly.io/policy-viewer/policy.html?policyUUID=1a722415-444a-4fcb-be2e-8a9d20a6e2d8"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="https://app.termly.io/policy-viewer/policy.html?policyUUID=42ccfec6-1d3b-4431-8b48-803ae369514b"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                Cookie Policy
              </a>
            </li>
            <li>
              <div className={styles.link}>
                {/* eslint-disable-next-line */}
                <a
                  href="#"
                  className="termly-display-preferences"
                  style={{ color: "var(--SECONDARY-COLOR)" }}
                  onClick={(e) => e.preventDefault()}
                >
                  Consent Preferences
                </a>
              </div>
            </li>
          </ul>
          <p className={styles.copy}>&copy; 2024 Iter Lexici, LLC</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
