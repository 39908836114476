import React, { useContext } from "react";
import { AuthContext } from "../../../../features/auth/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFeather } from "@fortawesome/free-solid-svg-icons";

import styles from "./MainNav.module.css";

const Subscribe = ({ isDesktopView }) => {
  const { auth, isAdmin, sub, createCheckoutSession } = useContext(AuthContext);

  let content;

  if (!isAdmin) {
    content =
      auth && !sub ? (
        <button className={styles.subscribeButton}>
          <div
            onClick={createCheckoutSession}
            className={styles.subscribeContainer}
          >
            <FontAwesomeIcon
              icon={faFeather}
              className={styles.subscribeIcon}
            />
            {isDesktopView ? (
              <p className={styles.subscribeText}>subscribe</p>
            ) : (
              <></>
            )}
          </div>
        </button>
      ) : (
        <></>
      );
  }

  return content;
};

export default Subscribe;
