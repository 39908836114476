import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import {
  PWD_REGEX,
  PWD_LENGTH_REGEX,
  PWD_LOWERCASE_REGEX,
  PWD_UPPERCASE_REGEX,
  PWD_NUMBER_REGEX,
  PWD_SPECIAL_REGEX,
} from "../../../config/regex";
import InputRequirementCheckMsg from "../../../components/shared/InputRequirementCheckMsg/InputRequirementCheckMsg";
import LoadMsg from "../../../components/shared/LoadMsg/LoadMsg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTriangleExclamation,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import useTitle from "../../../hooks/useTitle";

import styles from "./ResetPassword.module.css";

const Form = () => {
  useTitle("Reset Password");

  const { isLoading, resetPassword } = useContext(AuthContext);

  const navigate = useNavigate();

  const errRef = useRef();
  const successRef = useRef();
  const passwordRef = useRef();

  const [errField, setErrField] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [changedPassword, setChangedPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (errMsg) {
      errRef.current.focus();
    }
  }, [errMsg]);

  useEffect(() => {
    if (errField) {
      switch (errField) {
        case "password":
          passwordRef.current.focus();
          break;
        default:
          break;
      }
    }
  }, [errField]);

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(changedPassword));
  }, [changedPassword]);

  const handlePasswordInput = (e) => {
    if (errField) setErrField("");
    setChangedPassword(e.target.value);
  };

  const canSubmit = validPassword;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (canSubmit) {
      try {
        await resetPassword(changedPassword);
        setErrMsg("");
        successRef.current?.focus();
        setChangedPassword("");
        navigate("/login");
      } catch (err) {
        setErrMsg(err.data?.message);
        setErrField("password");
      }
    }
  };

  let content;

  if (isLoading) {
    // content = <LoadMsg msg="Updating password..." size="2x" />;
    content = <LoadMsg msg="loading" size="2x" />;
  } else {
    content = (
      <div className={styles.container}>
        <h2 className={styles.warningMsg}>
          <FontAwesomeIcon icon={faTriangleExclamation} />
          Reset Password
        </h2>

        {errMsg && (
          <p
            ref={errRef}
            className="form__errorMsg"
            aria-live="assertive"
            role="alert"
          >
            {errMsg}
          </p>
        )}

        {/* edit password */}
        <form className="profileForm" onSubmit={handleSubmit}>
          <div className="form__col">
            <div className="form__col">
              <label className="form__label" htmlFor="password">
                Password:
              </label>
              <p className="form__requirements">
                8-64 characters, must include: uppercase & lowercase letter,
                number, & special character
              </p>
            </div>
            <div className="form__row relative">
              <input
                className={`form__input
                ${
                  errField === "password"
                    ? "form__input--invalid"
                    : changedPassword && validPassword
                    ? "form__input--valid"
                    : changedPassword && !validPassword
                    ? "form__input--invalid"
                    : ""
                }
                `}
                type={showPassword ? "text" : "password"}
                id="password"
                value={changedPassword}
                onChange={handlePasswordInput}
                autoComplete="off"
                required
                autoFocus
                tabIndex="1"
                ref={passwordRef}
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="form__showPassword"
                title={showPassword ? "Hide password" : "Show password"}
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            <InputRequirementCheckMsg
              value={changedPassword}
              regex={PWD_LENGTH_REGEX}
              requirement={"8-64 characters"}
            />
            <InputRequirementCheckMsg
              value={changedPassword}
              regex={PWD_LOWERCASE_REGEX}
              requirement={"at least 1 lowercase letter"}
            />
            <InputRequirementCheckMsg
              value={changedPassword}
              regex={PWD_UPPERCASE_REGEX}
              requirement={"at least 1 uppercase letter"}
            />
            <InputRequirementCheckMsg
              value={changedPassword}
              regex={PWD_NUMBER_REGEX}
              requirement={"at least 1 number"}
            />
            <InputRequirementCheckMsg
              value={changedPassword}
              regex={PWD_SPECIAL_REGEX}
              requirement={"at least 1 special character"}
            />
          </div>

          <button
            className={
              canSubmit ? "form__submitButton" : "form__submitButton--disabled"
            }
            disabled={!canSubmit}
            tabIndex="0"
          >
            Change password
          </button>
        </form>
      </div>
    );
  }
  return content;
};

export default Form;
