import React, { useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "../AuthContext";
import {
  setNotification,
  selectNotification,
} from "../../../components/shared/AppLayout/notification/notificationSlice";
import FormErrMsg from "../../../components/shared/FormErrMsg/FormErrMsg";
import useTitle from "../../../hooks/useTitle";
import LoadMsg from "../../../components/shared/LoadMsg/LoadMsg";

import styles from "./Verification.module.css";
import SuccessMsg from "../../../components/shared/SuccessMsg/SuccessMsg";

const VerificationExpired = () => {
  useTitle("Verification Expired");

  const dispatch = useDispatch();

  const { resendVerificationEmail, isLoading } = useContext(AuthContext);

  const { type, message } = useSelector(selectNotification);

  const urlParams = new URLSearchParams(window.location.search);
  const username = urlParams.get("username");
  const email = urlParams.get("email");

  // set notification upon init page load
  const effectRan = useRef(false);
  useEffect(() => {
    if (!effectRan.current) {
      dispatch(
        setNotification({
          type: "error",
          message: "email verification link expired",
          additionalFields: {
            username: username,
            email: email,
          },
        })
      );
    }

    return () => {
      effectRan.current = true;
    };
  }, [dispatch, email, username, type]);

  let content;

  if (isLoading) {
    content = (
      <div className={styles.loadingContainer}>
        {/* <LoadMsg msg="resending verification email" size="1x" /> */}
        <LoadMsg msg="loading" size="1x" />
      </div>
    );
  } else {
    if (type === "error" && message === "email verification link expired") {
      content = (
        <>
          <h2 className={styles.h2_error}>Error</h2>
          <FormErrMsg
            elements={[
              <p>
                Verification failed because the link expired.{" "}
                <span
                  onClick={() => resendVerificationEmail(email)}
                  className={`nowrap ${styles.resend}`}
                >
                  Get new link
                </span>
              </p>,
            ]}
          />
        </>
      );
    } else if (type === "success" && message === "resent verification email") {
      content = (
        <>
          <h2 className={styles.h2_success}>Success</h2>
          <SuccessMsg
            elements={[
              <p className={styles.sent}>
                Resent verification email. Check your inbox to verify your
                account.
              </p>,
            ]}
          />
        </>
      );
    }
  }

  return (
    <main className="page-wrapper">
      <div className="grid-center">
        <section className={styles.section}>{content}</section>
      </div>
    </main>
  );
};

export default VerificationExpired;
