import React, { useState } from "react";
import { POS_VALUE_DESCRIPTIONS } from "../attributeValueDescriptions";
import toCamelCase from "../../../../../utils/toCamelCase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./MorphologyItem.module.css";

const PosMorphologyItem = ({
  attributeName,
  attributeColor,
  attributeValue,
  attributeNameDescription,
  // attributeValueDescription,
  selected,
}) => {
  const [showingValueDescription, setShowingDescription] = useState(false);

  // attributeName = "part of speech"
  // attributeValue = array of 1 or more strings
  // attributeValueDescription 1 or more descriptions

  return (
    <div className={styles.container}>
      {showingValueDescription ? (
        <>
          <section
            className={
              selected
                ? styles.descriptionContainer__selected
                : styles.descriptionContainer
            }
          >
            <div className={styles.itemContainer}>
              <div className={styles.itemInfoContainer}>
                <p className={`${attributeColor} ${styles.attributeName}`}>
                  {attributeName}:{" "}
                </p>
                <FontAwesomeIcon
                  className={styles.icon}
                  icon={faChevronDown}
                  onClick={() => setShowingDescription(false)}
                />
              </div>
              <p className={styles.attributeNameDescription}>
                {attributeNameDescription}
              </p>
            </div>
            {attributeValue.map((attr, idx) => (
              <p key={idx} className={styles.valueDescription}>
                <>
                  {POS_VALUE_DESCRIPTIONS[toCamelCase(attr)] ? (
                    <>
                      <span className={styles.valueSpan}>{attr}</span> -{" "}
                      {POS_VALUE_DESCRIPTIONS[toCamelCase(attr)]}
                    </>
                  ) : (
                    <>
                      <span className={styles.valueSpan}>{attr}</span>
                    </>
                  )}
                </>
              </p>
            ))}
            {/* {attributeValue && (
              <p className={styles.valueDescription}>
                <>
                  {attributeValueDescription ? (
                    <>
                      <span className={styles.valueSpan}>{attributeValue}</span>{" "}
                      - {attributeValueDescription}
                    </>
                  ) : (
                    <>
                      <span className={styles.valueSpan}>{attributeValue}</span>
                    </>
                  )}
                </>
              </p>
            )} */}
          </section>
        </>
      ) : (
        // collapsed
        <div
          className={selected ? styles.itemRow__selected : styles.itemRow}
          onClick={() => setShowingDescription(true)}
        >
          <p className={styles.item}>
            <span
              className={`${attributeColor}
              ${styles.attributeName}
              `}
            >
              {attributeName}:{" "}
            </span>
            {/* {attributeValue} */}
            {attributeValue.map((attr, idx) => (
              <React.Fragment key={idx}>
                {attr}
                {idx !== attributeValue.length - 1 ? ", " : ""}
              </React.Fragment>
            ))}
          </p>
          <div className={styles.infoIcon}>
            {showingValueDescription ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PosMorphologyItem;
