// todo: no spanish
export const LANGUAGE_VALUE_DESCRIPTIONS = {
  // spanish:
  //   "The modern form of Spanish which begins from the late 15th century and is still in use today",
  spanish: null,
  latin:
    "An ancient language of the Roman Empire, used from around the 6th century BC until the fall of the Roman Empire in the 5th century AD",
};

export const POS_VALUE_DESCRIPTIONS = {
  noun: "A person, place or thing",
  adjective: "Describes or modifies a noun",
  pronoun: "Holds the place of a noun",
  adposition:
    "Shows direction, location, time, manner, cause, purpose or possession",
  verb: "Describes an action, occurence or state",
  coordinatingConjunction:
    "Connects words, phrases or clauses of equal importance",
  subordinatingConjunction:
    "Connects an independent clause to a dependent clause",
  adverb: "Describes or modifies a verb",
  determiner: "Introduces a noun",
  abbreviation: "A shortened form of a word or phrase",
  interjection: "Expresses a sudden emotion or reaction",
  particle: "A small function word which does not change form",
  properNoun: "A specific name given to a person, place or thing",
  numeral: "Denotes numbers or quantities",
};

export const DETERMINER_TYPE_VALUE_DESCRIPTIONS = {
  definite:
    "Specifies a particular, identifiable noun known to the listener or reader; it makes the noun definite",
  demonstrative:
    "Points to a specific noun and indicates its proximity to the speaker, denoting near or far in space or time.",
  indefinite:
    "Refers to a non-specific or unspecified noun, indicating any member of a class",
  possessive: "Indicates ownership or possession of the noun that follows",
  quantifier:
    "Expresses the quantity of the noun, indicating how much or how many",
};

// todo: add reflexive, intensifier
export const PRONOUN_TYPE_VALUE_DESCRIPTIONS = {
  personal: "Refers to a specific person or thing",
  impersonal: "Refers to general or unspecified subjects",
  possessive: "Shows ownership or possession",
  demonstrative: "Points to specific things",
  interrogative: "Used to ask questions",
  relative: "Introduces relative clauses",
  indefinite: "Refers to nonspecific things",
  reciprocal: "Indicates a mutual action or relationship",
  quantifier: "Expresses quantities",
};

export const PRONOUN_FUNCTION_VALUE_DESCRIPTIONS = {
  subject: "Indicates who or what performs the action",
  directObject: "Indicates the recipient of the action",
  indirectObject: "Indicates the indirect recipient of the action",
  reflexive: "Indicates a subject acting upon itself",
};

export const VERB_TYPE_VALUE_DESCRIPTIONS = {
  ar: "The infinitive (lemma) of the verb ends in -ar",
  er: "The infinitive (lemma) of the verb ends in -er",
  ir: "The infinitive (lemma) of the verb ends in -ir",
};

export const FORM_VALUE_DESCRIPTIONS = {
  infinitive: "The verb's base form",
  finite: "Inflected to show tense, person, number and mood",
  pastParticiple: "Used with an auxiliary verb to indicate a completed action",
  presentParticiple: "Indicates an ongoing action",
};

export const MOOD_VALUE_DESCRIPTIONS = {
  indicative:
    "Used to make factual statements, ask questions, or express opinions that are believed to be true",
  subjunctive:
    "Used to express wishes, hypothetical situations, demands, or actions that are contrary to fact",
  imperative: "Used to give commands, make requests, or offer invitations",
};

export const TENSE_VALUE_DESCRIPTIONS = {
  present:
    "Used to describe actions or states that are currently happening, habitual actions, or general truths",
  preterite:
    "Used to describe actions or events that were completed in the past",
  imperfect:
    "Used to describe ongoing or habitual actions, states, or conditions in the past without specifying a definite beginning or end",
  future: "Used to describe actions or events that will happen at a later time",
  conditional:
    "Used to describe actions or events that would happen under certain conditions or hypothetical situations",
};

export const PERSON_VALUE_DESCRIPTIONS = {
  first: "Indicates a self-reference of the speaker",
  second: "Indicates a reference to the addressee of the speaker",
  third:
    "Indicates a reference to someone or something which is neither the speaker nor the addressee",
};

export const GENDER_VALUE_DESCRIPTIONS = {
  masculine:
    "Refers to a category of words typically, but not always, associated with male entities and qualities",
  feminine:
    "Refers to a category of words typically, but not always, associated with female entities and qualities",
  neuter: "Refers to a noun of unknown gender",
};

export const NUMBER_VALUE_DESCRIPTIONS = {
  singular: "Indicates a composition of one",
  plural: "Indicates a composition of more than one",
};

export const CLITIC_FUNCTION_VALUE_DESCRIPTIONS = {
  directObject: "Indicates the recipient of the action",
  indirectObject: "Indicates the indirect recipient of the action",
  reflexive: "Indicates a subject acting upon itself",
};

export const CLITIC_PERSON_VALUE_DESCRIPTIONS = {
  first: "Indicates a self-reference of the speaker",
  second: "Indicates a reference to the addressee of the speaker",
  third:
    "Indicates a reference to someone or something which is neither the speaker nor the addressee",
};

export const CLITIC_GENDER_VALUE_DESCRIPTIONS = {
  masculine:
    "Refers to a category of words typically, but not always, associated with male entities and qualities",
  feminine:
    "Refers to a category of words typically, but not always, associated with female entities and qualities",
};

export const CLITIC_NUMBER_VALUE_DESCRIPTIONS = {
  singular: "Indicates a composition of one",
  plural: "Indicates a composition of more than one",
};
