import React, { useEffect, useCallback, useRef } from "react";
import { useGetUserProfileQuery } from "../../profile/profileApiSlice";
import { useSelector } from "react-redux";
import { selectSentenceById } from "../../sentence/sentencesApiSlice";
import { selectWordInstancesBySentenceId } from "../wordInstancesApiSlice";
import MorphologyContent from "./MorphologyContent/MorphologyContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faToggleOn,
  faToggleOff,
  faVolumeHigh,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./Info.module.css";

const Info = ({
  bookId,
  chapter,
  currentSentenceId,
  bookTitle,
  selectedWord,
  selectedFragment,
  showTranslationSection,
  setShowTranslationSection,
  showGrammarSection,
  setShowGrammarSection,
  getVocabularyByFragmentId,
}) => {
  const { data: userProfile } = useGetUserProfileQuery("userProfile");

  const currentSentence = useSelector((state) =>
    selectSentenceById(state, bookId, chapter, currentSentenceId)
  );
  const currentWordInstances = useSelector((state) =>
    selectWordInstancesBySentenceId(state, bookId, chapter, currentSentenceId)
  );
  const selectedWordInstance = currentWordInstances.find(
    (wi) => wi._id === selectedWord
  );

  const audioRef = useRef(null);
  // initialize only once
  if (!audioRef.current) {
    audioRef.current = new Audio();
  }

  // memoized
  const onSentenceTTSClick = useCallback(() => {
    if (!audioRef.current.paused) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }

    const bookSrc = bookTitle.toLowerCase().replace(/[ ]/g, "-");
    const sentenceSrc = `${currentSentence.part}-${currentSentence.chapter}-${currentSentence.section}-${currentSentence.position}`;
    audioRef.current.src = `/${bookSrc}/${sentenceSrc}.wav`;

    console.log(audioRef.current.src);

    audioRef.current
      .play()
      .catch((error) => console.error("Error playing the audio file:", error));
  }, [bookTitle, currentSentence]);

  // setting1: autoPlayWordTTS
  useEffect(() => {
    if (userProfile?.settings?.autoPlaySentenceTTS) {
      onSentenceTTSClick();
    }
  }, [
    currentSentenceId,
    onSentenceTTSClick,
    userProfile?.settings?.autoPlaySentenceTTS,
  ]);

  // TTS key mapping (s)
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "s") {
        event.preventDefault();
        event.stopPropagation();
        onSentenceTTSClick();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onSentenceTTSClick]); // onSentenceTTSClick is stable now

  return (
    <div className={styles.gridInfo}>
      <div className={styles.gridInfo_content}>
        {/* left side */}
        <div className={styles.translationCol}>
          <div
            className={`${styles.header} ${
              showTranslationSection ? `` : `${styles.header__off}`
            }`}
          >
            <div
              className={styles.headerToggle__sentence}
              onClick={() => setShowTranslationSection(!showTranslationSection)}
            >
              {showTranslationSection ? (
                <FontAwesomeIcon
                  icon={faToggleOn}
                  title="Hide Sentence Translation"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faToggleOff}
                  title="Show Sentence Translation"
                />
              )}
            </div>
            sentence
            <div className={styles.headerTTSButton}>
              <FontAwesomeIcon
                icon={faVolumeHigh}
                title='Play Sentence Text to Speech -  "S" Key'
                onClick={onSentenceTTSClick}
              />
            </div>
          </div>
          <div className={styles.translationContent}>
            {showTranslationSection && (
              <>
                <p className={styles.sentenceTranslation}>
                  {currentSentence.translation} {/** FIXME */}
                </p>
              </>
            )}
          </div>
        </div>

        {/* right side */}
        <div className={styles.grammarCol}>
          <div
            className={`${styles.header} ${
              showGrammarSection ? `` : `${styles.header__off}`
            }`}
          >
            <div
              className={styles.headerToggle__grammar}
              onClick={() => setShowGrammarSection(!showGrammarSection)}
            >
              {showGrammarSection ? (
                <FontAwesomeIcon
                  icon={faToggleOn}
                  title="Hide Morphology Info"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faToggleOff}
                  title="Show Morphology Info"
                />
              )}{" "}
            </div>
            morphology
          </div>
          {showGrammarSection && (
            <MorphologyContent
              selectedWordInstance={selectedWordInstance}
              getVocabularyByFragmentId={getVocabularyByFragmentId}
              selectedFragment={selectedFragment}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Info;
