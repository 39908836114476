import { apiSlice } from "../../app/api/apiSlice";

export const contactApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    contactUs: builder.mutation({
      query: (formData) => ({
        url: "/contact/contact-us",
        method: "POST",
        body: formData,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    reportIssue: builder.mutation({
      query: (formData) => ({
        url: "/contact/report-issue",
        method: "POST",
        body: formData,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
  }),
});

export const { useContactUsMutation, useReportIssueMutation } = contactApiSlice;
