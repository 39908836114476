import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import notificationReducer from "../components/shared/AppLayout/notification/notificationSlice";
import authReducer from "../features/auth/authSlice";
import { setupListeners } from "@reduxjs/toolkit/query";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer, // cache
    notification: notificationReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),

  devTools: process.env.NODE_ENV === "development",
});

setupListeners(store.dispatch);
