import React, { useRef, useState, useEffect } from "react";
import { useCreateNewCourseMutation } from "./adminApiSlice";
import LoadMsg from "../../components/shared/LoadMsg/LoadMsg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./AdminIndex.module.css";

const CreateNewCourse = () => {
  const errRef = useRef();
  const successRef = useRef();

  const [showingForm, setShowingForm] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [nativeLanguageName, setNativeLanguageName] = useState("");
  const [targetLanguageName, setTargetLanguageName] = useState("");

  const [CreateNewCourse, { isLoading }] = useCreateNewCourseMutation();

  const clearMsgs = () => {
    setErrMsg("");
    setSuccessMsg("");
  };

  useEffect(() => {
    clearMsgs();
  }, [title, description, nativeLanguageName, targetLanguageName]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearMsgs();
    try {
      const { message, isError } = await CreateNewCourse({
        title,
        description,
        nativeLanguageName,
        targetLanguageName,
      }).unwrap();
      if (isError) {
        throw new Error(message);
      }
      setSuccessMsg(message);
      successRef.current?.focus();
    } catch (err) {
      console.error(err);
      setErrMsg(err?.data?.message || err.message);
      errRef.current?.focus();
    }
  };

  let content;

  if (isLoading) {
    content = (
      <div className={styles.loadingContainer}>
        <LoadMsg msg="creating course" size="1x" />
      </div>
    );
  } else {
    content = (
      <section className={styles.section}>
        <div
          onClick={() => setShowingForm(!showingForm)}
          className={styles.headerRow}
        >
          {showingForm ? (
            <FontAwesomeIcon className={styles.icon} icon={faChevronDown} />
          ) : (
            <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
          )}
          <h2 className={styles.h2}>POST /courses</h2>
        </div>
        {showingForm && (
          <>
            {errMsg && (
              <p
                ref={errRef}
                className={styles.errMsg}
                aria-live="assertive"
                role="alert"
              >
                {errMsg}
              </p>
            )}

            {successMsg && (
              <p
                ref={successRef}
                className={styles.successMsg}
                aria-live="assertive"
                role="alert"
              >
                {successMsg}
              </p>
            )}

            <form className="form__admin" onSubmit={handleSubmit}>
              {/* title */}
              <div className="form__col">
                <div className="form__row">
                  <label className="form__label" htmlFor="title">
                    title: *
                  </label>
                </div>
                <div className="form__row grow">
                  <input
                    type="text"
                    className="form__input grow"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    autoComplete="off"
                    required
                    autoFocus
                    tabIndex="1"
                  />
                </div>
              </div>

              {/* description */}
              <div className="form__col">
                <div className="form__row">
                  <label className="form__label" htmlFor="description">
                    description: *
                  </label>
                </div>
                <div className="form__row grow">
                  <input
                    type="text"
                    className="form__input grow"
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    autoComplete="off"
                    required
                    tabIndex="2"
                  />
                </div>
              </div>

              {/* nativeLanguageName */}
              <div className="form__col">
                <div className="form__row">
                  <label className="form__label" htmlFor="nativeLanguageName">
                    nativeLanguageName: *
                  </label>
                </div>
                <div className="form__row grow">
                  <input
                    type="text"
                    className="form__input grow"
                    id="nativeLanguageName"
                    value={nativeLanguageName}
                    onChange={(e) => setNativeLanguageName(e.target.value)}
                    autoComplete="off"
                    required
                    tabIndex="3"
                  />
                </div>
              </div>

              {/* targetLanguageName */}
              <div className="form__col">
                <div className="form__row">
                  <label className="form__label" htmlFor="targetLanguageName">
                    targetLanguageName: *
                  </label>
                </div>
                <div className="form__row grow">
                  <input
                    type="text"
                    className="form__input grow"
                    id="targetLanguageName"
                    value={targetLanguageName}
                    onChange={(e) => setTargetLanguageName(e.target.value)}
                    autoComplete="off"
                    required
                    tabIndex="4"
                  />
                </div>
              </div>

              {/* submit button by default since only 1 button in this form */}
              <button className="form__submitButton" tabIndex="5">
                create course
              </button>
            </form>
          </>
        )}
      </section>
    );
  }

  return content;
};

export default CreateNewCourse;
