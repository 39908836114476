import React, { useContext } from "react";
import { AuthContext } from "../../auth/AuthContext";
import { useCreatePortalSessionMutation } from "../profileApiSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFeather } from "@fortawesome/free-solid-svg-icons";
import { faStripe } from "@fortawesome/free-brands-svg-icons";

import styles from "./StripeDisplay.module.css";

const ProductDisplay = () => {
  const { createCheckoutSession } = useContext(AuthContext);

  return (
    <section className={styles.container}>
      <div className={styles.productTopRow}>
        <h3 className={styles.h3}>
          Subscribe to <span className={styles.companyName}>Iter Lexici</span>
        </h3>
        <p className={styles.price}>$14.99 / month</p>
      </div>
      <p className={styles.description}>
        <span className={`nowrap`}>Access</span> all of the content in our
        growing library.{" "}
      </p>
      <p className={`nowrap ${styles.cancel}`}>cancel anytime</p>
      <div className={styles.bottomRow}>
        <button
          onClick={createCheckoutSession}
          className={styles.subscribeButton}
          id="checkout-and-portal-button"
        >
          <FontAwesomeIcon icon={faFeather} className={styles.subscribeIcon} />
          <p className={styles.subscribeText}>subscribe</p>
        </button>
        <div className={styles.stripeContainer}>
          <p className={styles.stripeText}>powered by</p>
          <div className={styles.stripeIconContainer}>
            <FontAwesomeIcon className={styles.stripeIcon} icon={faStripe} />
          </div>
        </div>
      </div>
    </section>
  );
};

const SuccessDisplay = ({ customerId }) => {
  const [createPortalSession] = useCreatePortalSessionMutation();

  const handleManageSubscription = async () => {
    try {
      const result = await createPortalSession({ customerId }).unwrap();

      if (result && result.url) {
        window.location.href = result.url; // Redirect to the Stripe Billing Portal
      }
    } catch (err) {
      console.error("Failed to create Stripe Portal session:", err);
    }
  };

  return (
    <section className={styles.container}>
      <div className={styles.productTopRow}>
        <h3 className={styles.h3}>
          Thank you for subscribing to{" "}
          <span className={styles.companyName}>Iter Lexici</span>
        </h3>
      </div>
      <p className={styles.description}>
        You have access to all of our content.
      </p>
      <div className={styles.bottomRow}>
        <button
          onClick={handleManageSubscription}
          className={styles.subscribeButton}
          id="checkout-and-portal-button"
        >
          <FontAwesomeIcon icon={faFeather} className={styles.subscribeIcon} />
          <p className={styles.subscribeText}>manage</p>
        </button>
        <div className={styles.stripeContainer}>
          <p className={styles.stripeText}>powered by</p>
          <div className={styles.stripeIconContainer}>
            <FontAwesomeIcon className={styles.stripeIcon} icon={faStripe} />
          </div>
        </div>
      </div>
    </section>
  );
};

const StripeDisplay = ({ sub, customerId }) => {
  if (!sub) {
    return <ProductDisplay />;
  } else {
    return <SuccessDisplay customerId={customerId} />;
  }
};

export default StripeDisplay;
