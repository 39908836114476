// not intended for use with clitics
export const contributingFragment = (wordInstance, attribute) => {
  // check that attribute appears in word instance
  if (wordInstance[attribute]) {
    const wordInstanceAttributeValue = wordInstance[attribute];

    // create array of non-null fragment objects
    const fragments = [
      { stem: wordInstance.stem },
      { ending1: wordInstance.ending1 },
      { ending2: wordInstance.ending2 },
      { ending3: wordInstance.ending3 },
    ].filter((obj) => {
      // filters out objects with a null value
      return Object.values(obj)[0] !== null;
    });

    // loop thru each fragment obj and return the key of the first obj whose value contains the matching attribute
    for (let i = 0; i < fragments.length; i++) {
      // look at the value (fragment object as it appears in the word instance in the cache) and check if attribute matches
      if (
        Object.values(fragments[i])[0][attribute] === wordInstanceAttributeValue
      ) {
        return Object.keys(fragments[i])[0];
      }
    }
  }

  return null;
};
