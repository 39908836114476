import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import styles from "./LoadMsg.module.css";

const LoadMsg = ({ msg, size, dynaHeight, hero }) => {
  return (
    <div
      className={`${styles.container} 
      ${dynaHeight === true ? styles.dynaHeight : ``}
      ${hero === true ? `heroHeight` : ``}
      `}
    >
      <FontAwesomeIcon spin size={size} icon={faSpinner} />
      {msg && <p>{msg}</p>}
    </div>
  );
};

export default LoadMsg;
