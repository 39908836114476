import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { AuthContext } from "../../../../features/auth/AuthContext";
import { useGetUserProfileQuery } from "../../../../features/profile/profileApiSlice";
import LogoMono from "../../LogoMono/Logo";
import Subscribe from "./Subscribe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faXmark,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./MainNav.module.css";

const MainNav = () => {
  const { auth, isAdmin, sub, username } = useContext(AuthContext);
  const { data: userProfile } = useGetUserProfileQuery("userProfile");
  const displayUsername = username || userProfile?.username;

  const location = useLocation();

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);
  // isTabletView may be useful later
  // const [isTabletView, setIsTabletView] = useState(
  //   (window.innerWidth > 500) & (window.innerWidth <= 600)
  // );
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 600);
  const [drawerShowing, setDrawerShowing] = useState(false);

  // listen for mobile/tablet/desktop view resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 500);
      // setIsTabletView((window.innerWidth > 500) & (window.innerWidth <= 600));
      setIsDesktopView(window.innerWidth > 600);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setDrawerShowing(false);
  }, [location.pathname]);

  useEffect(() => {
    drawerShowing && !auth && setDrawerShowing(false);
  }, [drawerShowing, auth]);

  useEffect(() => {
    if (!isMobileView) {
      setDrawerShowing(false);
    }
  }, [isMobileView]);

  const toggleDrawerShowing = () => {
    setDrawerShowing(!drawerShowing);
  };

  return (
    <header className={styles.container}>
      <div className={styles.topRow}>
        {/* logo */}
        <div className={styles.logoContainer}>
          <Link to="/">
            <LogoMono />
          </Link>
        </div>

        {/* menu */}
        {auth ? (
          isMobileView ? (
            <div className={styles.hamburgerContainer}>
              <button
                className={styles.hamburger}
                onClick={toggleDrawerShowing}
              >
                {drawerShowing ? (
                  <FontAwesomeIcon icon={faXmark} />
                ) : (
                  <FontAwesomeIcon icon={faBars} />
                )}
              </button>
            </div>
          ) : (
            <div className={styles.desktopMenuContainer}>
              <nav>
                <ul className={styles.desktopMenuList}>
                  <li>
                    <NavLink
                      to="library"
                      className={({ isActive }) =>
                        isActive
                          ? `${styles.desktopMenuItem} ${styles.active}`
                          : `${styles.desktopMenuItem}`
                      }
                    >
                      library
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="profile"
                      className={({ isActive }) =>
                        isActive
                          ? `${styles.desktopMenuItem} ${styles.active}`
                          : `${styles.desktopMenuItem}`
                      }
                    >
                      profile
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="settings"
                      className={({ isActive }) =>
                        isActive
                          ? `${styles.desktopMenuItem} ${styles.active}`
                          : `${styles.desktopMenuItem}`
                      }
                    >
                      settings
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          )
        ) : (
          <></>
        )}
        <div className={styles.subAuthContainer}>
          {/* subscribe button*/}
          <Subscribe isDesktopView={isDesktopView} />

          {/* auth */}
          <div className={styles.authContainer}>
            {auth ? (
              <Link to="profile" className={styles.userLink}>
                <FontAwesomeIcon
                  icon={faUserCheck}
                  className={
                    isAdmin || sub ? styles.userIcon__sub : styles.userIcon
                  }
                />
                {displayUsername && (
                  <span
                    className={
                      isAdmin || sub ? styles.username__sub : styles.username
                    }
                  >
                    {" "}
                    {displayUsername?.slice(0, 5)}
                  </span>
                )}
              </Link>
            ) : (
              <>
                <li>
                  <Link to="login" className={styles.login}>
                    login
                  </Link>
                </li>
                <li>
                  <Link to="sign-up" className={styles.signUp}>
                    sign up
                  </Link>
                </li>
              </>
            )}
          </div>
        </div>
      </div>

      {/* mobile menu drawer */}
      {drawerShowing ? (
        <nav className={styles.drawer}>
          <ul className={styles.mobileMenu}>
            <li className={styles.mobileMenu_item}>
              <Link to="library" className={styles.mobileMenu_link}>
                <div className={styles.mobileMenu_link_row}>library</div>
              </Link>
            </li>
            <li className={styles.mobileMenu_item}>
              <Link to="profile" className={styles.mobileMenu_link}>
                <div className={styles.mobileMenu_link_row}>profile</div>
              </Link>
            </li>
            <li className={styles.mobileMenu_item}>
              <Link to="settings" className={styles.mobileMenu_link}>
                <div className={styles.mobileMenu_link_row}>settings</div>
              </Link>
            </li>
          </ul>
        </nav>
      ) : (
        <></>
      )}
    </header>
  );
};

export default MainNav;
