import {
  LANGUAGE_VALUE_DESCRIPTIONS,
  POS_VALUE_DESCRIPTIONS,
  DETERMINER_TYPE_VALUE_DESCRIPTIONS,
  PRONOUN_TYPE_VALUE_DESCRIPTIONS,
  PRONOUN_FUNCTION_VALUE_DESCRIPTIONS,
  VERB_TYPE_VALUE_DESCRIPTIONS,
  FORM_VALUE_DESCRIPTIONS,
  MOOD_VALUE_DESCRIPTIONS,
  TENSE_VALUE_DESCRIPTIONS,
  PERSON_VALUE_DESCRIPTIONS,
  GENDER_VALUE_DESCRIPTIONS,
  NUMBER_VALUE_DESCRIPTIONS,
  CLITIC_FUNCTION_VALUE_DESCRIPTIONS,
  CLITIC_PERSON_VALUE_DESCRIPTIONS,
  CLITIC_GENDER_VALUE_DESCRIPTIONS,
  CLITIC_NUMBER_VALUE_DESCRIPTIONS,
} from "./attributeValueDescriptions";
import toCamelCase from "../../../../utils/toCamelCase";
import { toTextualPerson } from "../../../../utils/toTextualPerson";
import { contributingFragment } from "./contributingFragment";

export const ATTRIBUTE_LIST = (
  selectedWordInstance,
  getVocabularyByFragmentId,
  selectedFragment
) => {
  // get vocabulary state of each fragment
  const stemVocabularyState = selectedWordInstance?.stem?._id
    ? getVocabularyByFragmentId(selectedWordInstance?.stem?._id)?.state
    : null;

  const ending1VocabularyState = selectedWordInstance?.ending1?._id
    ? getVocabularyByFragmentId(selectedWordInstance?.ending1?._id)?.state
    : null;

  const ending2VocabularyState = selectedWordInstance?.ending2?._id
    ? getVocabularyByFragmentId(selectedWordInstance?.ending2?._id)?.state
    : null;

  const ending3VocabularyState = selectedWordInstance?.ending3?._id
    ? getVocabularyByFragmentId(selectedWordInstance?.ending3?._id)?.state
    : null;

  // create obj for easy fragment vocabulary state access
  const FRAGMENT_VOCABULARY_STATES = {
    stem: stemVocabularyState,
    ending1: ending1VocabularyState,
    ending2: ending2VocabularyState,
    ending3: ending3VocabularyState,
  };

  // create obj for east fragment _id access
  const FRAGMENT_IDS = {
    stem: selectedWordInstance?.stem?._id,
    ending1: selectedWordInstance?.ending1?._id,
    ending2: selectedWordInstance?.ending2?._id,
    ending3: selectedWordInstance?.ending3?._id,
  };

  // format contraction
  const contractionString = selectedWordInstance?.word?.contraction.replace(
    /,/g,
    ", "
  );

  // format pos
  const posArr = selectedWordInstance?.word.pos.split(",");
  // console.log(`posArr: ${posArr}`);

  const posString = toCamelCase(posArr[0]);

  // determine which fragment contributes which attributes and the vocabulary state of each
  // form
  const formContributingFragment = contributingFragment(
    selectedWordInstance,
    "form"
  );
  const formColor = FRAGMENT_VOCABULARY_STATES[formContributingFragment];

  // mood
  const moodContributingFragment = contributingFragment(
    selectedWordInstance,
    "mood"
  );
  const moodColor = FRAGMENT_VOCABULARY_STATES[moodContributingFragment];

  // tense
  const tenseContributingFragment = contributingFragment(
    selectedWordInstance,
    "tense"
  );
  const tenseColor = FRAGMENT_VOCABULARY_STATES[tenseContributingFragment];

  // person
  const personContributingFragment = contributingFragment(
    selectedWordInstance,
    "person"
  );
  const personColor = FRAGMENT_VOCABULARY_STATES[personContributingFragment];

  // gender
  const genderContributingFragment = contributingFragment(
    selectedWordInstance,
    "gender"
  );
  const genderColor = FRAGMENT_VOCABULARY_STATES[genderContributingFragment];

  // number
  const numberContributingFragment = contributingFragment(
    selectedWordInstance,
    "number"
  );
  const numberColor = FRAGMENT_VOCABULARY_STATES[numberContributingFragment];

  // determine which fragment contributes which clitic attributes
  let clitic1ContributingFragment = null;
  let clitic1Color = null;

  let clitic2ContributingFragment = null;
  let clitic2Color = null;

  // create array of fragments
  let clitics = [
    { stem: selectedWordInstance?.stem },
    { ending1: selectedWordInstance?.ending1 },
    { ending2: selectedWordInstance?.ending2 },
    { ending3: selectedWordInstance?.ending3 },
  ]
    .filter((obj) => {
      return Object.values(obj)[0] !== null; // first filter out null values
    })
    .filter((obj) => {
      return Object.values(obj)[0].clitic === true; // then filter out non clitics
    });

  // assign first clitic function, person, gender, number to first fragment that is a clitic & has matching attributes
  if (
    clitics.length > 0 &&
    (selectedWordInstance?.clitic1Function ||
      selectedWordInstance?.clitic1Person ||
      selectedWordInstance?.clitic1Gender ||
      selectedWordInstance?.clitic1Number)
  ) {
    // check that every attribute matches exactly
    if (
      Object.values(clitics[0])[0].cliticFunction ===
        selectedWordInstance?.clitic1Function &&
      Object.values(clitics[0])[0].cliticPerson ===
        selectedWordInstance?.clitic1Person &&
      Object.values(clitics[0])[0].cliticGender ===
        selectedWordInstance?.clitic1Gender &&
      Object.values(clitics[0])[0].cliticNumber ===
        selectedWordInstance?.clitic1Number
    ) {
      // assign
      clitic1ContributingFragment = Object.keys(clitics[0])[0];
      clitic1Color = FRAGMENT_VOCABULARY_STATES[clitic1ContributingFragment];
      // remove clitic from clitics array
      clitics.shift();
    }
  }

  // assign second clitic function, person, gender, number to first fragment that is a clitic & has matching attributes
  if (
    clitics.length > 0 &&
    (selectedWordInstance?.clitic2Function ||
      selectedWordInstance?.clitic2Person ||
      selectedWordInstance?.clitic2Gender ||
      selectedWordInstance?.clitic2Number)
  ) {
    // check that every attribute matches exactly
    if (
      Object.values(clitics[0])[0].cliticFunction ===
        selectedWordInstance?.clitic2Function &&
      Object.values(clitics[0])[0].cliticPerson ===
        selectedWordInstance?.clitic2Person &&
      Object.values(clitics[0])[0].cliticGender ===
        selectedWordInstance?.clitic2Gender &&
      Object.values(clitics[0])[0].cliticNumber ===
        selectedWordInstance?.clitic2Number
    ) {
      // assign
      clitic2ContributingFragment = Object.keys(clitics[0])[0];
      clitic2Color = FRAGMENT_VOCABULARY_STATES[clitic2ContributingFragment];
    }
  }

  // build list
  if (selectedWordInstance?.word?.language?.name !== "Spanish") {
    return [
      {
        attributeName: "language",
        attributeColor: null,
        attributeValue: selectedWordInstance?.word?.language?.name,
        attributeNameDescription: "The language which the word belongs to",
        attributeValueDescription:
          LANGUAGE_VALUE_DESCRIPTIONS[
            toCamelCase(selectedWordInstance?.word?.language?.name)
          ],
        selected: false,
      },
      {
        attributeName: "part of speech",
        attributeColor: `stem ${stemVocabularyState}`,
        attributeValue: posArr,
        attributeNameDescription: "Indicates a word's function",
        attributeValueDescription: POS_VALUE_DESCRIPTIONS[posString],
        selected: FRAGMENT_IDS.stem === selectedFragment,
      },
    ].filter((obj) => obj.attributeValue);
  } else {
    return [
      {
        attributeName: "language",
        attributeColor: null,
        attributeValue:
          selectedWordInstance?.word?.language?.name === "Spanish"
            ? null
            : selectedWordInstance?.word?.language?.name,
        attributeNameDescription: "The language which the word belongs to",
        attributeValueDescription:
          LANGUAGE_VALUE_DESCRIPTIONS[
            toCamelCase(selectedWordInstance?.word?.language?.name)
          ],
        selected: false,
      },
      {
        attributeName: "modern equivalent",
        attributeColor: null,
        attributeValue: selectedWordInstance?.word?.modernEquivalent,
        attributeNameDescription: "The modern language's corresponding word",
        attributeValueDescription: null,
        selected: false,
      },
      {
        attributeName: "lemma",
        attributeColor: null,
        attributeValue: selectedWordInstance?.word?.lemma,
        attributeNameDescription: "A word's base form",
        attributeValueDescription: null,
        selected: false,
      },
      {
        attributeName: "expected word",
        attributeColor: null,
        attributeValue: selectedWordInstance?.word?.expectedWord,
        attributeNameDescription:
          "The expected form of the word were it not irregular",
        attributeValueDescription: null,
        selected: false,
      },
      {
        attributeName: "contraction",
        attributeColor: null,
        attributeValue: contractionString,
        attributeNameDescription:
          "A shortened form of two or more words that are combined to create a new word",
        attributeValueDescription: null,
        selected: false,
      },
      {
        attributeName: "part of speech",
        attributeColor: `stem ${stemVocabularyState}`,
        attributeValue: posArr,
        attributeNameDescription: "Indicates a word's function",
        attributeValueDescription: null,
        selected: FRAGMENT_IDS.stem === selectedFragment,
      },
      {
        attributeName: "pronoun type",
        attributeColor: `stem ${stemVocabularyState}`,
        attributeValue: selectedWordInstance?.pronounType,
        attributeNameDescription:
          "A sub-category of pronouns indicating more specifically their usage in a sentence",
        attributeValueDescription:
          PRONOUN_TYPE_VALUE_DESCRIPTIONS[
            toCamelCase(selectedWordInstance?.pronounType)
          ],
        selected: FRAGMENT_IDS.stem === selectedFragment,
      },
      {
        attributeName: "pronoun function",
        attributeColor: `stem ${stemVocabularyState}`,
        attributeValue: selectedWordInstance?.pronounFunction,
        attributeNameDescription: "The function of the pronoun",
        attributeValueDescription:
          PRONOUN_FUNCTION_VALUE_DESCRIPTIONS[
            toCamelCase(selectedWordInstance?.pronounFunction)
          ],
        selected: FRAGMENT_IDS.stem === selectedFragment,
      },
      {
        attributeName: "verb type",
        attributeColor: `stem ${stemVocabularyState}`,
        attributeValue: selectedWordInstance?.verbType,
        attributeNameDescription:
          "Determines which set of endings a verb will take",
        attributeValueDescription:
          VERB_TYPE_VALUE_DESCRIPTIONS[
            selectedWordInstance?.verbType?.replace(/-/g, "")
          ],
        selected: FRAGMENT_IDS.stem === selectedFragment,
      },
      {
        attributeName: "form",
        attributeColor: `${formContributingFragment} ${formColor}`,
        attributeValue: selectedWordInstance?.form,
        attributeNameDescription: "The broadest category of verb",
        attributeValueDescription:
          FORM_VALUE_DESCRIPTIONS[toCamelCase(selectedWordInstance?.form)],
        selected: FRAGMENT_IDS[formContributingFragment] === selectedFragment,
      },
      {
        attributeName: "mood",
        attributeColor: `${moodContributingFragment} ${moodColor}`,
        attributeValue: selectedWordInstance?.mood,
        attributeNameDescription:
          "The speaker's attitude in the use of the verb",
        attributeValueDescription:
          MOOD_VALUE_DESCRIPTIONS[toCamelCase(selectedWordInstance?.mood)],
        selected: FRAGMENT_IDS[moodContributingFragment] === selectedFragment,
      },
      {
        attributeName: "tense",
        attributeColor: `${tenseContributingFragment} ${tenseColor}`,
        attributeValue: selectedWordInstance?.tense,
        attributeNameDescription: "Indicates the time aspect of the verb",
        attributeValueDescription:
          TENSE_VALUE_DESCRIPTIONS[toCamelCase(selectedWordInstance?.tense)],
        selected: FRAGMENT_IDS[tenseContributingFragment] === selectedFragment,
      },
      {
        attributeName: "person",
        attributeColor: `${personContributingFragment} ${personColor}`,
        attributeValue: selectedWordInstance?.person,
        attributeNameDescription: "Indicates the perspective of the subject",
        attributeValueDescription:
          PERSON_VALUE_DESCRIPTIONS[
            toTextualPerson(selectedWordInstance?.person)
          ],
        selected: FRAGMENT_IDS[personContributingFragment] === selectedFragment,
      },
      {
        attributeName: "gender",
        attributeColor: `${genderContributingFragment} ${genderColor}`,
        attributeValue: selectedWordInstance?.gender,
        attributeNameDescription:
          "A classification of words which affects agreement with others",
        attributeValueDescription:
          GENDER_VALUE_DESCRIPTIONS[toCamelCase(selectedWordInstance?.gender)],
        selected: FRAGMENT_IDS[genderContributingFragment] === selectedFragment,
      },
      {
        attributeName: "number",
        attributeColor: `${numberContributingFragment} ${numberColor}`,
        attributeValue: selectedWordInstance?.number,
        attributeNameDescription: "Indicates the numerical composition",
        attributeValueDescription:
          NUMBER_VALUE_DESCRIPTIONS[toCamelCase(selectedWordInstance?.number)],
        selected: FRAGMENT_IDS[numberContributingFragment] === selectedFragment,
      },
      {
        attributeName: "first clitic function",
        attributeColor: `${clitic1ContributingFragment} ${clitic1Color}`,
        attributeValue: selectedWordInstance?.clitic1Function,
        attributeNameDescription:
          "The 1st clitic's role as it relates to the verb",
        attributeValueDescription:
          CLITIC_FUNCTION_VALUE_DESCRIPTIONS[
            toCamelCase(selectedWordInstance?.clitic1Function)
          ],
        selected:
          FRAGMENT_IDS[clitic1ContributingFragment] === selectedFragment,
      },
      {
        attributeName: "first clitic person",
        attributeColor: `${clitic1ContributingFragment} ${clitic1Color}`,
        attributeValue: selectedWordInstance?.clitic1Person,
        attributeNameDescription:
          "The perspective of the noun represented by the 1st clitic",
        attributeValueDescription:
          CLITIC_PERSON_VALUE_DESCRIPTIONS[
            toTextualPerson(selectedWordInstance?.clitic1Person)
          ],
        selected:
          FRAGMENT_IDS[clitic1ContributingFragment] === selectedFragment,
      },
      {
        attributeName: "first clitic gender",
        attributeColor: `${clitic1ContributingFragment} ${clitic1Color}`,
        attributeValue: selectedWordInstance?.clitic1Gender,
        attributeNameDescription:
          "A classification of the noun represented by the 1st clitic which affects agreement with other words",
        attributeValueDescription:
          CLITIC_GENDER_VALUE_DESCRIPTIONS[
            toCamelCase(selectedWordInstance?.clitic1Gender)
          ],
        selected:
          FRAGMENT_IDS[clitic1ContributingFragment] === selectedFragment,
      },
      {
        attributeName: "first clitic number",
        attributeColor: `${clitic1ContributingFragment} ${clitic1Color}`,
        attributeValue: selectedWordInstance?.clitic1Number,
        attributeNameDescription:
          "The numerical composition of the noun represented by the 1st clitic",
        attributeValueDescription:
          CLITIC_NUMBER_VALUE_DESCRIPTIONS[
            toCamelCase(selectedWordInstance?.clitic1Number)
          ],
        selected:
          FRAGMENT_IDS[clitic1ContributingFragment] === selectedFragment,
      },
      {
        attributeName: "second clitic function",
        attributeColor: `${clitic2ContributingFragment} ${clitic2Color}`,
        attributeValue: selectedWordInstance?.clitic2Function,
        attributeNameDescription:
          "The 2nd clitic's role as it relates to the verb",
        attributeValueDescription:
          CLITIC_FUNCTION_VALUE_DESCRIPTIONS[
            toCamelCase(selectedWordInstance?.clitic2Function)
          ],
        selected:
          FRAGMENT_IDS[clitic2ContributingFragment] === selectedFragment,
      },
      {
        attributeName: "second clitic person",
        attributeColor: `${clitic2ContributingFragment} ${clitic2Color}`,
        attributeValue: selectedWordInstance?.clitic2Person,
        attributeNameDescription:
          "The perspective of the noun represented by the 2nd clitic",
        attributeValueDescription:
          CLITIC_PERSON_VALUE_DESCRIPTIONS[
            toTextualPerson(selectedWordInstance?.clitic2Person)
          ],
        selected:
          FRAGMENT_IDS[clitic2ContributingFragment] === selectedFragment,
      },
      {
        attributeName: "second clitic gender",
        attributeColor: `${clitic2ContributingFragment} ${clitic2Color}`,
        attributeValue: selectedWordInstance?.clitic2Gender,
        attributeNameDescription:
          "A classification of the noun represented by the 2nd clitic which affects agreement with other words",
        attributeValueDescription:
          CLITIC_GENDER_VALUE_DESCRIPTIONS[
            toCamelCase(selectedWordInstance?.clitic2Gender)
          ],
        selected:
          FRAGMENT_IDS[clitic2ContributingFragment] === selectedFragment,
      },
      {
        attributeName: "second clitic number",
        attributeColor: `${clitic2ContributingFragment} ${clitic2Color}`,
        attributeValue: selectedWordInstance?.clitic2Number,
        attributeNameDescription:
          "The numerical composition of the noun represented by the 2nd clitic",
        attributeValueDescription:
          CLITIC_NUMBER_VALUE_DESCRIPTIONS[
            toCamelCase(selectedWordInstance?.clitic2Number)
          ],
        selected:
          FRAGMENT_IDS[clitic2ContributingFragment] === selectedFragment,
      },
    ].filter((obj) => obj.attributeValue);
  }
};
