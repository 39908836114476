import React from "react";
import { ATTRIBUTE_LIST } from "./attributeList";
import MorphologyItem from "./MorphologyItem/MorphologyItem";

import styles from "./MorphologyContent.module.css";

const MorphologyContent = ({
  selectedWordInstance,
  getVocabularyByFragmentId,
  selectedFragment,
}) => {
  let content;

  if (selectedWordInstance) {
    const attributes = ATTRIBUTE_LIST(
      selectedWordInstance,
      getVocabularyByFragmentId,
      selectedFragment
    );

    content = attributes.map((obj) => {
      return (
        <MorphologyItem
          key={obj.attributeName}
          attributeName={obj.attributeName}
          attributeColor={obj.attributeColor}
          attributeValue={obj.attributeValue}
          attributeNameDescription={obj.attributeNameDescription}
          attributeValueDescription={obj.attributeValueDescription}
          selected={obj.selected}
        />
      );
    });
  }

  return <div className={styles.container}>{content}</div>;
};

export default MorphologyContent;
