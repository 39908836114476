import React, { useContext } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import LoadMsg from "../../components/shared/LoadMsg/LoadMsg";

const RequireNoAuth = () => {
  const location = useLocation();
  const { auth, isLoading } = useContext(AuthContext);

  let content;

  if (isLoading) {
    content = <LoadMsg msg={"loading"} size={"2x"} />;
  } else if (auth) {
    // redirect to library if authenticated
    content = <Navigate to="/library" state={{ from: location }} replace />;
  } else {
    content = <Outlet />;
  }

  return content;
};

export default RequireNoAuth;
