import React, { useContext } from "react";
import { TourContext } from "../TourProvider";
import Logo from "../../../../components/shared/LogoV2/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion /*, faWrench*/ } from "@fortawesome/free-solid-svg-icons";
import {
  faChevronUp,
  faChevronDown,
  faGear,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./Nav.module.css";
import tourStyles from "../Tour.module.css";

const Nav = ({
  setTourActive,
  setShowingTourPopup,
  currentSentenceId,
  SENTENCES,
  totalSentences,
  onNextSentenceClick,
  onPrevSentenceClick,
  knownCount,
  setShowingSSVSettings,
  setShowingSSVHelp,
  handleShowSSVSettingsClick,
  handleShowSSVHelpClick,
}) => {
  const { currentTask, setCurrentTask, nextTask, prevTask } =
    useContext(TourContext);

  const currentSentence = SENTENCES.entities[currentSentenceId];

  return (
    <>
      <div
        className={`${styles.logo}
        ${currentTask === 24 ? `${tourStyles.taskFocusNoBorder}` : ``}`}
        onClick={() => {
          if (currentTask === 24) {
            setTourActive(false);
            setShowingTourPopup(false);
            setCurrentTask(0);
          }
        }}
        title="Exit to Library"
      >
        <Logo />
      </div>

      <div
        className={`${styles.locationContainer}
      ${currentTask === 18 ? `${tourStyles.taskFocus__unclickable}` : ``}
      ${currentTask === 19 ? `${tourStyles.taskFocus}` : ``}
      ${currentTask === 20 ? `${tourStyles.taskFocus__unclickable}` : ``}
      `}
      >
        <FontAwesomeIcon
          className={
            currentSentence?.position > 1
              ? `${styles.icon}`
              : `${styles.icon__disabled}`
          }
          icon={faChevronUp}
          onClick={onPrevSentenceClick}
          title='Go to Previous Sentence - "P" Key'
        />
        <div className={styles.locationText}>
          <h2 className={styles.bookTitle}>Book Title</h2>
          <h3 className={styles.chapterTitle}>Chapter</h3>
          <p className={styles.sentencePosition}>
            sentence {currentSentence?.position}/{totalSentences}
          </p>
        </div>
        <FontAwesomeIcon
          className={`${
            currentSentence?.position < totalSentences
              ? `${styles.icon}`
              : `${styles.icon__disabled}`
          }
          ${currentTask === 19 ? `${tourStyles.taskFocus}` : ``}
          `}
          icon={faChevronDown}
          onClick={() => {
            onNextSentenceClick();
            nextTask();
          }}
          title='Go to Next Sentence - "N" Key'
        />
      </div>

      <div className={styles.header2__columnContainer}>
        <div
          className={`${styles.header2__iconRow}
        ${currentTask === 23 ? `${tourStyles.taskFocus__unclickable}` : ``}`}
        >
          <FontAwesomeIcon
            className={styles.icon}
            icon={faGear}
            title="Settings"
            onClick={handleShowSSVSettingsClick}
          />
          <FontAwesomeIcon
            className={`${styles.icon}`}
            icon={faQuestion}
            title="Help"
            onClick={handleShowSSVHelpClick}
          />
        </div>
        <div
          className={`${styles.knownCountContainer}
        ${currentTask === 14 ? `${tourStyles.taskFocus__unclickable}` : ``}
        `}
        >
          <img
            className={styles.flag}
            src="/img/flags/Spanish.png"
            alt="Spanish flag"
          ></img>
          <p
            className={`nowrap ${styles.knownCount}
            
            `}
            title="Total Known Fragments"
          >
            {/* {"--"} */}
            {knownCount ? knownCount : "--"}
          </p>
        </div>
      </div>
    </>
  );
};

export default Nav;
