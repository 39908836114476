import React from "react";
import useTitle from "../../../../../hooks/useTitle";

import styles from "./About.module.css";

const About = () => {
  useTitle("About");

  const content = (
    <section className={styles.container}>
      <h2 className={styles.header}>About</h2>
      <article className={styles.article}>
        <p className={styles.p}>
          Iter Lexici aims to cut the time required to acquire foreign languages
          by a significant margin. To do so, we employ the principles of famous
          linguist Stephen Krashen. While somewhat well-known, we find that his
          tenets are ultimately executed poorly. According to Krashen, languages
          are acquired via input, and that input merely needs to be
          comprehenisble, interesting/relevant, not grammatically sequenced, and
          provided in sufficient quanity. Most apps fail three of four, where
          others only one or two of four. In fulfilling all four, we expect to
          improve time efficiency significantly.
        </p>
        <p className={styles.p}>
          We are just a team of two where Noah is mostly responsible for matters
          of technology and where Harrison is mostly repsonsible for matters of
          linguistics. You can keep up with us on the Iter Lexici Podcast every
          week where we'll enjoy our first book and see how quickly Noah can
          acquire Spanish using Iter Lexici only. You can find clips on our
          Instagram, and we will be active on Reddit.
        </p>
      </article>
    </section>
  );

  return (
    <main className="page-wrapper">
      <div className="grid-center">{content}</div>
    </main>
  );
};

export default About;
