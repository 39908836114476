import React, { useState, useEffect, /*useMemo,*/ useRef } from "react";
import { useParams, Link } from "react-router-dom";
// import { useSelector } from "react-redux";
import {
  useGetUserProfileQuery,
  useUpdateUserKnownFragmentCountMutation,
  useUpdateLastReadMutation,
} from "../profile/profileApiSlice";
import {
  useGetUserVocabularyQuery,
  useUpsertUserVocabularyMutation,
} from "../profile/vocabularyApiSlice";
// import { selectBookById } from "../library/booksApiSlice";
import { useGetBookByIdQuery } from "../library/booksApiSlice";
import { useGetAllSentencesInBookQuery } from "../sentence/sentencesApiSlice";
import { useGetAllWordInstancesInBookQuery } from "./wordInstancesApiSlice";
import { skipToken } from "@reduxjs/toolkit/query/react";
import LoadMsg from "../../components/shared/LoadMsg/LoadMsg";
import ErrMsg from "../../components/shared/ErrMsg/ErrMsg";
import Nav from "./Nav/Nav";
import Target from "./Target/Target";
import Info from "./Info/Info";
import Toolbar from "./Toolbar/Toolbar";
import TourPopup from "./TourPopup/TourPopup";
import { TourProvider } from "./Tour/TourProvider";
import Tour from "./Tour/Tour";
import useTitle from "../../hooks/useTitle";
import scrollToTop from "../../utils/scrollToTop";

import styles from "./Sentence.module.css";
import { triggerAnimation } from "../../utils/triggerAnimation";

const Sentence = ({
  showingSSVSettings,
  showingSSVHelp,
  setShowingSSVSettings,
  setShowingSSVHelp,
  handleShowSSVSettingsClick,
  handleShowSSVHelpClick,
  showingTourPopup,
  setShowingTourPopup,
  tourActive,
  setTourActive,
  setCurrentTourStep,
}) => {
  useEffect(() => {
    function setDynamicHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty(
        "--dynamic-height",
        `${vh * 100}px`
      );
    }

    function handleViewportChange() {
      const vh = window.visualViewport
        ? window.visualViewport.height * 0.01
        : window.innerHeight * 0.01;
      document.documentElement.style.setProperty(
        "--dynamic-height",
        `${vh * 100}px`
      );
    }

    setDynamicHeight();
    window.addEventListener("resize", setDynamicHeight);
    window.addEventListener("scroll", setDynamicHeight);
    window.visualViewport &&
      window.visualViewport.addEventListener("resize", handleViewportChange);

    return () => {
      window.removeEventListener("resize", setDynamicHeight);
      window.removeEventListener("scroll", setDynamicHeight);
      window.visualViewport &&
        window.visualViewport.removeEventListener(
          "resize",
          handleViewportChange
        );
    };
  }, []);

  useTitle("Single Sentence View");

  const { bookId, chapter } = useParams();

  const [currentSentenceId, setCurrentSentenceId] = useState(null);
  const [selectedWord, setSelectedWord] = useState(null);
  const [selectedFragment, setSelectedFragment] = useState(null);
  const [translationVisible, setTranslationVisible] = useState({});
  const [fragmentState, setFragmentState] = useState({});
  const [showTranslationSection, setShowTranslationSection] = useState(false);
  const [showGrammarSection, setShowGrammarSection] = useState(true);
  const [knownCount, setKnownCount] = useState(null);

  // scroll to top of target when navigating sentences
  const targetSentenceRef = useRef(null);
  useEffect(() => {
    scrollToTop(targetSentenceRef);
  }, [currentSentenceId]);

  const { data: userProfile } = useGetUserProfileQuery("userProfile");

  const [upsertUserVocabulary] = useUpsertUserVocabularyMutation();

  const [updateUserKnownFragmentCount] =
    useUpdateUserKnownFragmentCountMutation();

  const [updateLastRead] = useUpdateLastReadMutation();

  const { data: vocabularies } = useGetUserVocabularyQuery("userVocabulary");

  const getVocabularyByFragmentId = (fragmentId) => {
    if (vocabularies) {
      return Object.values(vocabularies.entities).find(
        (vocab) => vocab.fragment === fragmentId
      );
    }
  };

  const resetSelections = () => {
    setSelectedWord(null);
    setSelectedFragment(null);
  };

  const {
    data: book,
    isLoading: book_isLoading,
    isError: book_isError,
    error: book_error,
  } = useGetBookByIdQuery(bookId);

  // initialize known fragment count
  useEffect(() => {
    if (book?.language && knownCount === null) {
      (async () => {
        try {
          const data = await updateUserKnownFragmentCount({
            languageId: book?.language,
          }).unwrap();
          setKnownCount(data.knownCount);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [book?.language, knownCount, updateUserKnownFragmentCount]);

  // get sentences
  const {
    data: sentences,
    isLoading: sentences_isLoading,
    isSuccess: sentences_isSuccess,
    isError: sentences_isError,
    error: sentences_error,
  } = useGetAllSentencesInBookQuery(book ? { bookId, chapter } : skipToken);

  // open book to last read sentence
  useEffect(() => {
    if (
      !currentSentenceId &&
      sentences_isSuccess &&
      sentences?.ids?.length &&
      userProfile
    ) {
      if (userProfile.lastRead && userProfile.lastRead[bookId]?.[chapter]) {
        setCurrentSentenceId(userProfile.lastRead[bookId][chapter]);
      } else {
        setCurrentSentenceId(sentences.ids[0]);
      }
    }
  }, [
    sentences,
    sentences_isSuccess,
    currentSentenceId,
    userProfile,
    bookId,
    chapter,
  ]);

  // save lastRead
  const handleSaveLastRead = async (id) => {
    try {
      await updateLastRead({
        bookId: bookId,
        chapter,
        sentenceId: id,
      }).unwrap();
    } catch (err) {
      console.error("error updating last read:", err);
    }
  };

  // get word instances
  const {
    data: wordInstances,
    isLoading: wordInstances_isLoading,
    isSuccess: wordInstances_isSuccess,
    isError: wordInstances_isError,
    error: wordInstances_error,
  } = useGetAllWordInstancesInBookQuery(book ? { bookId, chapter } : skipToken);

  let content;

  if (
    !tourActive &&
    (book_isLoading || sentences_isLoading || wordInstances_isLoading)
  ) {
    content = (
      <div className="grid-center">
        <LoadMsg msg="loading" size="2x" />
      </div>
    );
  }

  if (book_isError || sentences_isError || wordInstances_isError) {
    content = (
      <div className="grid-center">
        <ErrMsg
          msgList={[
            book_error?.data?.message,
            sentences_error?.data?.message,
            wordInstances_error?.data?.message,
          ]}
          additionalComponent={
            <Link className={styles.errLink} to="/library">
              return to libary
            </Link>
          }
          size="1x"
        />
      </div>
    );
  }

  if (
    book &&
    sentences_isSuccess &&
    currentSentenceId &&
    wordInstances_isSuccess &&
    wordInstances
  ) {
    // todo: fix this to work with chapters > 1
    const currentSentenceIndex = sentences?.ids.indexOf(currentSentenceId);
    // const currentSentenceIndex =
    //   sentences?.entities[currentSentenceId]?.position - 1;
    const totalSentences = sentences?.ids.length;

    const onNextSentenceClick = async () => {
      if (
        currentSentenceIndex !== undefined &&
        currentSentenceIndex >= 0 &&
        currentSentenceIndex < sentences.ids.length - 1
      ) {
        triggerAnimation("target", "fadeIn-animation");
        // scrollToTop();
        const nextSentenceId = sentences.ids[currentSentenceIndex + 1];
        setCurrentSentenceId(nextSentenceId);
        resetSelections();
        setShowTranslationSection(false);
        await handleSaveLastRead(nextSentenceId);
      }
    };

    const onPrevSentenceClick = async () => {
      if (currentSentenceIndex !== undefined && currentSentenceIndex > 0) {
        triggerAnimation("target", "fadeIn-animation");
        const prevSentenceId = sentences.ids[currentSentenceIndex - 1];
        setCurrentSentenceId(prevSentenceId);
        resetSelections();
        await handleSaveLastRead(prevSentenceId);
      }
    };

    const showTranslation = (wordInstanceId) => {
      setTranslationVisible((prev) => ({
        ...prev,
        [wordInstanceId]: true,
      }));
    };

    // mutation
    const toggleMarkAsKnown = async () => {
      if (selectedFragment) {
        const vocabulary = getVocabularyByFragmentId(selectedFragment);
        switch (vocabulary.state) {
          case "known":
            setKnownCount(knownCount - 1);
            break;
          default:
            setKnownCount(knownCount + 1);
            break;
        }
        try {
          const newState = vocabulary?.state === "known" ? "seen" : "known";

          await upsertUserVocabulary({
            vocabularyId: vocabulary?._id,
            fragmentId: selectedFragment,
            newState,
            languageId: book.language,
          }).unwrap();
          localStorage.setItem("vocabularyMutationFulfilled", "true");
        } catch (err) {
          console.error("error updating vocabulary:", err);
        }
      }
    };

    // mutation
    const markAsSeen = async (wordId, fragmentId, vocabulary) => {
      if (!vocabulary?._id || vocabulary?.state === "new") {
        // if (wordId) {
        try {
          await upsertUserVocabulary({
            vocabularyId: vocabulary?._id,
            fragmentId: fragmentId,
            newState: "seen",
            languageId: book.language,
          }).unwrap();
        } catch (err) {
          console.error("Error updating vocabulary:", err);
        }
        // }
      } // else if (!vocabulary?._id && fragmentId) {
      //   try {
      //     await createNewVocabulary({
      //       fragment
      //     })
      //   }
      // }
    };

    const handleFragmentSelection = (wordId, fragmentId, vocabulary) => {
      setSelectedFragment(fragmentId);
      setSelectedWord(wordId);
      showTranslation(wordId);
      markAsSeen(wordId, fragmentId, vocabulary);
    };

    const onNextFragmentClick = (currentWordInstances) => {
      let vocabulary;

      // check no selection
      if (!selectedWord || !selectedFragment) {
        vocabulary = getVocabularyByFragmentId(
          currentWordInstances[0]?.stem?._id
        );
        // select first word stem
        handleFragmentSelection(
          currentWordInstances[0]?._id,
          currentWordInstances[0]?.stem?._id,
          vocabulary
        );
        return;
      }

      // check selection
      const currentWord = currentWordInstances.find(
        (wi) => wi._id === selectedWord
      );
      if (!currentWord) return;

      // get word index
      const selectedWordIdx = currentWordInstances.findIndex(
        (wi) => wi._id === selectedWord
      );

      // get fragment index
      const fragments = ["stem", "ending1", "ending2", "ending3"]
        .map((fragment) => currentWord[fragment]?._id)
        .filter((id) => id);
      const selectedFragmentIdx = fragments.indexOf(selectedFragment);

      // check if additional fragments
      if (
        selectedFragmentIdx >= 0 &&
        selectedFragmentIdx < fragments.length - 1
      ) {
        vocabulary = getVocabularyByFragmentId(
          fragments[selectedFragmentIdx + 1]
        );
        // select next fragment
        handleFragmentSelection(
          selectedWord,
          fragments[selectedFragmentIdx + 1], // next fragment
          vocabulary
        );
        return;
      }

      // check if additional words
      else if (selectedWordIdx < currentWordInstances.length - 1) {
        vocabulary = getVocabularyByFragmentId(
          currentWordInstances[selectedWordIdx + 1].stem._id
        );
        // select next word stem
        handleFragmentSelection(
          currentWordInstances[selectedWordIdx + 1]._id, // next word
          currentWordInstances[selectedWordIdx + 1].stem._id, // stem
          vocabulary
        );
      }

      // if last word call onNextSentenceClick
      else {
        onNextSentenceClick();
      }
    };

    const onPrevFragmentClick = (currentWordInstances) => {
      let vocabulary;

      // check no selection
      if (!selectedWord || !selectedFragment) {
        // check prev sentence
        if (currentSentenceIndex > 0) {
          // go to prev sentence
          onPrevSentenceClick();
        }
        return;
      }

      // check selection
      const currentWord = currentWordInstances.find(
        (wi) => wi._id === selectedWord
      );
      if (!currentWord) return;

      // get word index
      const selectedWordIdx = currentWordInstances.findIndex(
        (wi) => wi._id === selectedWord
      );

      // get fragment index
      const fragments = ["stem", "ending1", "ending2", "ending3"]
        .map((fragment) => currentWord[fragment]?._id)
        .filter((id) => id);
      const selectedFragmentIdx = fragments.indexOf(selectedFragment);

      // check if prev fragments
      if (selectedFragmentIdx > 0) {
        vocabulary = getVocabularyByFragmentId(
          fragments[selectedFragmentIdx - 1]
        );
        // select prev fragment
        handleFragmentSelection(
          selectedWord,
          fragments[selectedFragmentIdx - 1], // prev fragment
          vocabulary
        );
        return;
      }

      // check if prev words
      else if (selectedWordIdx > 0) {
        // get prev word
        const prevWord = currentWordInstances[selectedWordIdx - 1];
        // get prev word fragments
        const prevWordFragments = ["stem", "ending1", "ending2", "ending3"]
          .map((fragment) => prevWord[fragment]?._id)
          .filter((id) => id);
        // get prev word last ending
        const prevWordLastFragment =
          prevWordFragments[prevWordFragments.length - 1];

        vocabulary = getVocabularyByFragmentId(prevWordLastFragment);
        // select prev word last ending
        handleFragmentSelection(
          currentWordInstances[selectedWordIdx - 1]._id, // prev word
          prevWordLastFragment,
          vocabulary
        );
      }

      // if first word call onPrevSentenceClick
      else {
        onPrevSentenceClick();
        // select last word last fragment?
      }
    };

    const toggleTranslationVisibility = () => {
      setTranslationVisible((prev) => ({
        ...prev,
        [selectedWord]: !prev[selectedWord],
      }));
    };

    const handleSeeTranslationClick = () => {
      toggleTranslationVisibility();
      // markAsSeen();
    };

    content = (
      <div className={styles.container}>
        {tourActive ? (
          <TourProvider>
            <Tour
              setTourActive={setTourActive}
              setShowingTourPopup={setShowingTourPopup}
              // currentTourStep={currentTourStep}
              // setCurrentTourStep={setCurrentTourStep}
              handleShowSSVSettingsClick={handleShowSSVSettingsClick}
              handleShowSSVHelpClick={handleShowSSVHelpClick}
            />
          </TourProvider>
        ) : (
          <>
            <div className={styles.grid}>
              <Nav
                bookId={bookId}
                chapter={chapter}
                currentSentenceId={currentSentenceId}
                currentSentenceIndex={currentSentenceIndex}
                totalSentences={totalSentences}
                bookTitle={book.title}
                onNextSentenceClick={onNextSentenceClick}
                onPrevSentenceClick={onPrevSentenceClick}
                knownCount={knownCount}
                showingSSVSettings={showingSSVSettings}
                showingSSVHelp={showingSSVHelp}
                setShowingSSVSettings={setShowingSSVSettings}
                setShowingSSVHelp={setShowingSSVHelp}
                handleShowSSVSettingsClick={handleShowSSVSettingsClick}
                handleShowSSVHelpClick={handleShowSSVHelpClick}
              />
              <Target
                targetSentenceRef={targetSentenceRef}
                bookId={bookId}
                chapter={chapter}
                currentSentenceId={currentSentenceId}
                beginsParagraph={
                  sentences?.entities[currentSentenceId]?.beginsParagraph ||
                  false
                }
                selectedWord={selectedWord}
                setSelectedWord={setSelectedWord}
                selectedFragment={selectedFragment}
                setSelectedFragment={setSelectedFragment}
                translationVisible={translationVisible}
                setTranslationVisible={setTranslationVisible}
                handleSeeTranslationClick={handleSeeTranslationClick}
                showTranslation={showTranslation}
                markAsSeen={markAsSeen}
                handleFragmentSelection={handleFragmentSelection}
                getVocabularyByFragmentId={getVocabularyByFragmentId}
              />
              <Info
                bookId={bookId}
                chapter={chapter}
                currentSentenceId={currentSentenceId}
                bookTitle={book.title}
                selectedWord={selectedWord}
                selectedFragment={selectedFragment}
                showTranslationSection={showTranslationSection}
                setShowTranslationSection={setShowTranslationSection}
                showGrammarSection={showGrammarSection}
                setShowGrammarSection={setShowGrammarSection}
                getVocabularyByFragmentId={getVocabularyByFragmentId}
              />
            </div>
            <div className={styles.overlay}>
              <Toolbar
                bookId={bookId}
                chapter={chapter}
                currentSentenceId={currentSentenceId}
                currentSentenceIndex={currentSentenceIndex}
                totalSentences={totalSentences}
                selectedWord={selectedWord}
                setSelectedWord={setSelectedWord}
                selectedFragment={selectedFragment}
                setSelectedFragment={setSelectedFragment}
                fragmentState={fragmentState}
                setFragmentState={setFragmentState}
                translationVisible={translationVisible}
                handleSeeTranslationClick={handleSeeTranslationClick}
                onNextFragmentClick={onNextFragmentClick}
                onPrevFragmentClick={onPrevFragmentClick}
                toggleMarkAsKnown={toggleMarkAsKnown}
                getVocabularyByFragmentId={getVocabularyByFragmentId}
              />
            </div>
            {(!userProfile.hasSeenTutorial || showingTourPopup) && (
              <TourPopup
                setTourActive={setTourActive}
                setCurrentTourStep={setCurrentTourStep}
                setShowingTourPopup={setShowingTourPopup}
              />
            )}
          </>
        )}
      </div>
    );
  }

  return <main className="page-wrapper">{content}</main>;
};

export default Sentence;
