// src/components/Home/HomeV3/Demo/Demo.js

import React, { useState, useCallback, useEffect, useMemo } from "react";
import Target from "./Target/Target";
import Toolbar from "./Toolbar/Toolbar";
import { SENTENCES, WORD_INSTANCES, VOCABULARIES } from "./demo-data";

import styles from "./Demo.module.css";

// Define the interval duration in milliseconds
const AUTO_CLICK_INTERVAL = 750; // 750 milliseconds

const Demo = ({ isEnglishToSpanish }) => {
  const [currentSentenceId] = useState("1");
  const [selectedWord, setSelectedWord] = useState(null);
  const [selectedFragment, setSelectedFragment] = useState(null);
  const [translationVisible, setTranslationVisible] = useState({});
  const [knownCount, setKnownCount] = useState(0);

  const sentences = SENTENCES;

  const currentSentence = sentences.entities[currentSentenceId];
  const currentSentenceText = isEnglishToSpanish
    ? currentSentence.spanish
    : currentSentence.english;

  const currentWordInstances = useMemo(
    () =>
      Object.values(WORD_INSTANCES.entities).filter(
        (obj) =>
          obj.sentence === currentSentenceId &&
          obj.language === (isEnglishToSpanish ? "Spanish" : "English")
      ),
    [currentSentenceId, isEnglishToSpanish]
  );

  const initVocabulary = useMemo(() => {
    return JSON.parse(JSON.stringify(VOCABULARIES.entities));
  }, [VOCABULARIES.entities]);

  const [vocabularies, setVocabularies] = useState(initVocabulary);

  const getVocabularyByFragmentId = useCallback(
    (fragmentId) => {
      if (vocabularies) {
        return Object.values(vocabularies).find(
          (vocab) => vocab.fragment === fragmentId
        );
      }
    },
    [vocabularies]
  );

  const resetSelections = useCallback(() => {
    setSelectedWord(null);
    setSelectedFragment(null);
  }, []);

  const handleStateReset = useCallback(() => {
    resetSelections();
    setTranslationVisible({}); // Reset translations
    setVocabularies(initVocabulary);
    setKnownCount(0);
  }, [resetSelections, initVocabulary]);

  // Reset state when language changes
  useEffect(() => {
    handleStateReset();
  }, [isEnglishToSpanish, handleStateReset]);

  const toggleMarkAsKnown = useCallback(() => {
    if (selectedFragment) {
      const vocabulary = getVocabularyByFragmentId(selectedFragment);
      if (vocabulary) {
        const newState = vocabulary.state === "known" ? "seen" : "known";
        setVocabularies((prev) => ({
          ...prev,
          [vocabulary._id]: {
            ...vocabulary,
            state: newState,
          },
        }));
        setKnownCount((prev) => (newState === "known" ? prev + 1 : prev - 1));
      }
    }
  }, [selectedFragment, getVocabularyByFragmentId]);

  const markAsSeen = useCallback((wordId, fragmentId, vocabulary) => {
    if (vocabulary && (!vocabulary._id || vocabulary.state === "new")) {
      setVocabularies((prev) => ({
        ...prev,
        [vocabulary._id]: {
          ...vocabulary,
          state: "seen",
        },
      }));
    }
  }, []);

  const handleFragmentSelection = useCallback(
    (wordId, fragmentId, vocabulary) => {
      setSelectedFragment(fragmentId);
      setSelectedWord(wordId);
      setTranslationVisible((prev) => ({
        ...prev,
        [wordId]: true, // Merge to keep existing visible translations
      }));
      markAsSeen(wordId, fragmentId, vocabulary);
    },
    [markAsSeen]
  );

  const onNextFragmentClick = useCallback(
    (currentWordInstances) => {
      let vocabulary;

      // Existing navigation logic...
      // (No changes needed here for auto-click functionality)
    },
    [
      selectedWord,
      selectedFragment,
      getVocabularyByFragmentId,
      handleFragmentSelection,
    ]
  );

  const onPrevFragmentClick = useCallback(
    (currentWordInstances) => {
      let vocabulary;

      // Existing navigation logic...
      // (No changes needed here for auto-click functionality)
    },
    [
      selectedWord,
      selectedFragment,
      getVocabularyByFragmentId,
      handleFragmentSelection,
    ]
  );

  const toggleTranslationVisibility = useCallback(() => {
    if (!selectedWord) return;
    setTranslationVisible((prev) => ({
      ...prev,
      [selectedWord]: !prev[selectedWord], // Toggle only current
    }));
  }, [selectedWord]);

  const handleSeeTranslationClick = useCallback(() => {
    toggleTranslationVisibility();
  }, [toggleTranslationVisibility]);

  // New State Variables for Auto-Clicking
  const [autoClickIndex, setAutoClickIndex] = useState(0);

  // Build fragmentsList using useMemo
  const fragmentsList = useMemo(() => {
    const list = [];
    currentWordInstances.forEach((wordInstance) => {
      const fragments = ["stem", "ending1", "ending2", "ending3"]
        .map((fragmentKey) => wordInstance[fragmentKey])
        .filter((fragment) => fragment && Object.keys(fragment).length !== 0);

      fragments.forEach((fragment) => {
        list.push({
          wordId: wordInstance._id,
          fragmentId: fragment._id,
        });
      });
    });
    return list;
  }, [currentWordInstances]);

  // Reset autoClickIndex when fragmentsList changes
  useEffect(() => {
    setAutoClickIndex(0);
  }, [fragmentsList]);

  // Automatic Fragment Selection with Delayed Reset
  useEffect(() => {
    if (autoClickIndex >= fragmentsList.length) {
      // All fragments have been auto-selected
      // Delay the reset to allow the last border to persist
      const resetTimer = setTimeout(() => {
        setSelectedWord(null);
        setSelectedFragment(null);

        // Optional: Clear all visible translations
        // Uncomment the line below if you want to hide all translations at the end
        // setTranslationVisible({});
      }, AUTO_CLICK_INTERVAL); // Delay equal to AUTO_CLICK_INTERVAL

      return () => clearTimeout(resetTimer); // Cleanup the timer
    }

    const timer = setTimeout(() => {
      const fragment = fragmentsList[autoClickIndex];
      if (fragment) {
        const vocabulary = getVocabularyByFragmentId(fragment.fragmentId);
        handleFragmentSelection(
          fragment.wordId,
          fragment.fragmentId,
          vocabulary
        );
        setAutoClickIndex((prevIndex) => prevIndex + 1);
      }
    }, AUTO_CLICK_INTERVAL); // Use the constant here

    return () => clearTimeout(timer); // Cleanup the timer on component unmount or before next effect
  }, [
    autoClickIndex,
    fragmentsList,
    getVocabularyByFragmentId,
    handleFragmentSelection,
  ]);

  let content;

  content = (
    <div className={styles.container}>
      {/* No speed control UI elements added */}
      <Target
        handleStateReset={handleStateReset}
        currentWordInstances={currentWordInstances}
        selectedWord={selectedWord}
        selectedFragment={selectedFragment}
        translationVisible={translationVisible}
        handleFragmentSelection={handleFragmentSelection}
        getVocabularyByFragmentId={getVocabularyByFragmentId}
        isEnglishToSpanish={isEnglishToSpanish}
      />
      {/* <Toolbar
        currentWordInstances={currentWordInstances}
        selectedFragment={selectedFragment}
        selectedWord={selectedWord}
        translationVisible={translationVisible}
        handleSeeTranslationClick={handleSeeTranslationClick}
        onNextFragmentClick={onNextFragmentClick}
        onPrevFragmentClick={onPrevFragmentClick}
        toggleMarkAsKnown={toggleMarkAsKnown}
        getVocabularyByFragmentId={getVocabularyByFragmentId}
        isEnglishToSpanish={isEnglishToSpanish}
      /> */}
    </div>
  );

  return content;
};

export default Demo;
