import { apiSlice } from "../../app/api/apiSlice";

export const adminApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createNewLanguage: builder.mutation({
      query: ({ name, isoCode }) => ({
        url: "/languages",
        method: "POST",
        body: { name, isoCode },
      }),
    }),
    createNewCourse: builder.mutation({
      query: ({
        title,
        description,
        nativeLanguageName,
        targetLanguageName,
      }) => ({
        url: "/courses",
        method: "POST",
        body: { title, description, nativeLanguageName, targetLanguageName },
      }),
    }),
    createNewBook: builder.mutation({
      query: ({ title, author, publishedDate, languageName }) => ({
        url: "/books",
        method: "POST",
        body: { title, author, publishedDate, languageName },
      }),
    }),
    autoCreateNewSentence: builder.mutation({
      query: ({ meta, wordInstances }) => ({
        url: "/auto/sentence",
        method: "POST",
        body: { meta, wordInstances },
      }),
    }),
    checkSentencesHealth: builder.mutation({
      query: ({ bookTitle }) => ({
        url: `/sentences/check-health?bookTitle=${encodeURIComponent(
          bookTitle
        )}`,
        method: "GET",
      }),
    }),
    resetUserVocabulary: builder.mutation({
      query: ({ username }) => ({
        url: "/vocabularies/reset-user-vocabulary",
        method: "PATCH",
        body: { username },
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    reinitializeAllUsersVocabularies: builder.mutation({
      query: () => ({
        url: "/vocabularies/reinitialize-all-users",
        method: "PUT",
        validateStatus: (response, result) => {
          return response.status === 201 && !result.isError;
        },
      }),
    }),
  }),
});

// RTK Query automatically creates the following hooks
export const {
  useCreateNewLanguageMutation,
  useCreateNewCourseMutation,
  useCreateNewBookMutation,
  useAutoCreateNewSentenceMutation,
  useCheckSentencesHealthMutation,
  useResetUserVocabularyMutation,
  useReinitializeAllUsersVocabulariesMutation,
} = adminApiSlice;
